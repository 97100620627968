import { api } from '../api'

export const documentRelationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRelatedDocuments: builder.query({
      // document-relations/companyId/page/pageSize
      query: ({ fileId, page, pageSize }) => ({
        url: `/document-relations/${fileId}/${page}/${pageSize}`,
      }),
      providesTags: ['DocumentRelations'],
    }),
    editRelation: builder.mutation({
      query: (body) => ({
        url: `/document-relations`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['DocumentRelations'],
    }),

    relateDocuments: builder.mutation({
      query: (body) => ({
        url: `/document-relations/relate-documents`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['DocumentRelations'],
    }),
    deleteRelation: builder.mutation({
      query: (id) => ({
        url: `/document-relations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DocumentRelations'],
    }),
  }),
})

export const {
  useGetRelatedDocumentsQuery,
  useLazyGetRelatedDocumentsQuery,
  useEditRelationMutation,
  useRelateDocumentsMutation,
  useDeleteRelationMutation,
} = documentRelationsApi
