import { createSlice } from '@reduxjs/toolkit'

const initialState = { openSidebar: true }

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setOpenSidebar: (state, action) => {
      state.openSidebar = action.payload
    },
  },
})

export const { setOpenSidebar } = sidebarSlice.actions
export default sidebarSlice.reducer
