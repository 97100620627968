import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useRestoreFilesMutation } from '../../../api/services/filesApi'
import NewModal from '../../root/modals/NewModal'

const ConfirmRestoreFileModal = ({ open, onClose, fileId }) => {
  const { t } = useTranslation()

  const [restoreFile] = useRestoreFilesMutation()

  const handleRestoreFile = async () => {
    try {
      const response = await restoreFile(fileId).unwrap()
      toast.success(response.message)
      onClose(false)
    } catch (error) {
      toast.error(error.data.message)
    }
    restoreFile(fileId)
    onClose(false)
  }

  return (
    <NewModal
      showModal={open}
      closeModal={onClose}
      hideClose
      title={t('you_sure_doc_resore')}
      btns
      noChildren
      titleClose={t('close')}
      titleSubmit={t('restore')}
      onClickClose={onClose}
      onClickSubmit={handleRestoreFile}
    />
  )
}

export default ConfirmRestoreFileModal
