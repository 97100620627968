import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const ProtectProtocol = ({ allowed }) => {
  const user = useSelector((state) => state.user.user)

  const location = useLocation()

  return user?.functions && user?.functions?.find((role) => allowed?.includes(role.name)) ? (
    <Outlet />
  ) : Object.keys(user)?.length && !user.functions?.length ? (
    <Navigate to="/homepage" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  )
}

export default ProtectProtocol
