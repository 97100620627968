import { Form, Formik } from 'formik'
import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../../api/services/configuratorApi'

function QRCode() {
  const company = useSelector((state) => state.company.company)
  const { data: config } = useGetConfiguratorQuery(company?.id)

  const initialValues = {
    ...config,
  }

  const [updateConfig] = useEditConfiguratorMutation()

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={(values, { setSubmitting }) => {}}>
      {({ values }) => {
        return (
          <Form className="flex justify-between w-full">
            <p className="w-[81px] h-[20px] font-popins font-medium leading-[20px] text-center text-lg text-[#073C59]">
              {t('qr')}
            </p>
            <input
              type="checkbox"
              name="QRcode"
              className={`toggle border-[#A6A9AD] ${config?.qr === true ? 'bg-white [--tglbg:#006EAB] hover:bg-white' : 'bg-[#006EAB] [--tglbg:white] hover:bg-[#006EAB]'} `}
              checked={config?.qr}
              onChange={async () => {
                try {
                  delete values?.num_key
                  const response = await updateConfig({
                    companyId: company?.id,
                    updatedConfig: { ...values, qr: !config.qr },
                  }).unwrap()
                  toast.success(response.message)
                } catch (error) {
                  toast.error(error.data.message)
                }
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default QRCode
