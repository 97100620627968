import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { v4 as uuidv4 } from 'uuid'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../api/services/configuratorApi'
import { useGetCustomStampsQuery, useUpdateCustomStampMutation } from '../../../api/services/stampsApi'
import NewModal from '../../root/modals/NewModal'
import ListOfFields from './components/ListOfFields'

const ConfigureStampModal = ({ open, onClose }) => {
  const [inputFields, setInputFields] = useState([])
  /**
   * Fields that the user sets "attributeKey" manual
   * The opposite of these fields are fields whos "attributeKey" is choosen
   * from the specific list of default fields
   */
  const [nonSelectStampFields, setNonSelectStampFields] = useState([])
  const company = useSelector((state) => state.company.company)

  const [updateCustomStamp] = useUpdateCustomStampMutation()
  const [updateCompanyConfig] = useEditConfiguratorMutation()

  // Stamp fields of the company
  const { data: stampFieldsData } = useGetCustomStampsQuery({ companyId: company.id })
  const { data: companyConfig } = useGetConfiguratorQuery(company?.id)

  // We must configure the stamp fields so they include the "uId" prop
  // It will be used in the onChange functions in ListOfFields.jsx component
  const stampFields = useMemo(
    () => (Array.isArray(stampFieldsData) ? stampFieldsData.map((field) => ({ ...field, uId: uuidv4() })) : []),
    [stampFieldsData],
  )

  // We want to set all the non-select stamp fields (ones user can name freely) to the state
  // This is so they can be edited and displayed on the screen
  useEffect(() => {
    if (stampFields) {
      setNonSelectStampFields((prev) =>
        stampFields.filter((field) => {
          if (
            field.attributeKey !== 'Stamp ID' &&
            field.attributeKey !== 'Predmet' &&
            field.attributeKey !== 'Broj fakture' &&
            field.attributeKey !== 'Klijent' &&
            field.attributeKey !== 'Partner' &&
            field.attributeKey !== 'Dobavljač' &&
            field.attributeKey !== 'Kupac' &&
            field.attributeKey !== 'Mjesto' &&
            field.attributeKey !== 'Sjedište' &&
            field.attributeKey !== 'Datum dokumenta' &&
            field.attributeKey !== 'Datum predmeta' &&
            field.attributeKey !== 'Datum protokola'
          ) {
            return field
          }
        }),
      )
    }
  }, [open])

  /**
   * spredValues are all the values that have been selected from the list of default fields
   * we want to filter out all the fields that have been set manulay
   * why is this done I DON'T KNOW
   * I have made this a long time ago and Im not touching too much, I just wanted to document something
   */
  const spredValues = (inputFields.length > 0 ? inputFields : stampFields).length
    ? inputFields.length > 0
      ? inputFields
      : stampFields?.filter((field) => {
          if (
            field.attributeKey === 'Stamp ID' ||
            field.attributeKey === 'Predmet' ||
            field.attributeKey === 'Broj fakture' ||
            field.attributeKey === 'Klijent' ||
            field.attributeKey === 'Partner' ||
            field.attributeKey === 'Dobavljač' ||
            field.attributeKey === 'Kupac' ||
            field.attributeKey === 'Mjesto' ||
            field.attributeKey === 'Sjedište' ||
            field.attributeKey === 'Datum dokumenta' ||
            field.attributeKey === 'Datum predmeta' ||
            field.attributeKey === 'Datum protokola'
          ) {
            return { ...field, select: true }
          }
        })
    : []

  const keyValue = [...inputFields, ...nonSelectStampFields].map((field) => {
    if (!field.attributeKey) {
      return false
    } else {
      return true
    }
  })

  //Set stamp template submit
  const handleStampChange = async () => {
    try {
      const check = keyValue.every((key) => key)
      if (!check) {
        toast.error(t('input_all_fields'))
        return
      } else {
        const response = await updateCustomStamp({
          companyId: company?.id,
          fields: [...spredValues, ...nonSelectStampFields],
        }).unwrap()

        toast.success(response.message)
        //Update company config to use created stamp
        const sendObj = {
          ...companyConfig,
          stampFields: 'custom',
        }

        updateCompanyConfig({ companyId: company?.id, updatedConfig: sendObj }).unwrap()
      }
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <NewModal
      showModal={open}
      title={t('config_stamp')}
      hideClose
      page={'configStamp'}
      setShowModal={onClose}
      btns
      titleClose={t('close')}
      titleSubmit={t('save')}
      onClickClose={onClose}
      onClickSubmit={handleStampChange}
    >
      <form className="">
        <ListOfFields
          spredValues={spredValues}
          nonSelectStampFields={nonSelectStampFields}
          setInputFields={setInputFields}
          // stampOptions={stampOptions}
          setNonSelectStampFields={setNonSelectStampFields}
        />
      </form>
    </NewModal>
  )
}

export default ConfigureStampModal
