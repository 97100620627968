import React from 'react'

const AdminIcon = () => {
  return (
    <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_182_7269)">
        <path
          d="M30.4891 13.1155C30.4891 14.0999 29.6687 14.8709 28.7391 14.8709H26.9891L27.0273 23.6319C27.0383 25.5733 25.4688 27.1483 23.5273 27.1483H6.00547C4.075 27.1483 2.50547 25.5788 2.50547 23.6483V14.8709H0.75C-0.234375 14.8709 -1 14.1053 -1 13.1155C-1 12.6233 -0.835938 12.1858 -0.453125 11.803L13.5687 -0.419678C13.9516 -0.80249 14.3891 -0.857178 14.7719 -0.857178C15.1547 -0.857178 15.5922 -0.747803 15.9203 -0.474365L29.8875 11.803C30.325 12.1858 30.5437 12.6233 30.4891 13.1155ZM18.25 11.3928C18.25 10.4646 17.8813 9.57433 17.2249 8.91795C16.5685 8.26157 15.6783 7.89282 14.75 7.89282C13.8217 7.89282 12.9315 8.26157 12.2751 8.91795C11.6187 9.57433 11.25 10.4646 11.25 11.3928C11.25 12.3211 11.6187 13.2113 12.2751 13.8677C12.9315 14.5241 13.8217 14.8928 14.75 14.8928C15.6783 14.8928 16.5685 14.5241 17.2249 13.8677C17.8813 13.2113 18.25 12.3211 18.25 11.3928ZM13 16.6428C10.5828 16.6428 8.625 18.6006 8.625 21.0178C8.625 21.4991 9.01875 21.8928 9.5 21.8928H20C20.4813 21.8928 20.875 21.4991 20.875 21.0178C20.875 18.6006 18.9172 16.6428 16.5 16.6428H13Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_7269">
          <rect width="29" height="25.7778" fill="white" transform="translate(0 0.142822)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AdminIcon
