import { useParams } from 'react-router-dom'

import TableRelations from '../components/table/files/TableRelations'

const ProtocolBookRelations = () => {
  const { id } = useParams()

  return <TableRelations primaryDocumentId={id} isOnProtocolBook />
}

export default ProtocolBookRelations
