import { ErrorMessage, useField, useFormikContext } from 'formik'
import React from 'react'
import Select from 'react-select'

import { clientStyles } from '../../data/createStampSelectStyles'

const SelectInput = ({ noLabel, label, changeKey, options, onChange, placeholder, isDisabled, ...props }) => {
  const [field] = useField(props)

  const { setFieldValue, setFieldTouched } = useFormikContext()
  return (
    <div>
      {noLabel ? (
        ''
      ) : (
        <label className={'text-xs text-[#043b61]'} htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <Select
        options={options}
        {...field}
        name={field.name}
        key={`my_unique_select_key__${changeKey}`}
        // onBlur={field.onBlur}
        onBlur={() => setFieldTouched(field.name, true)}
        onChange={
          onChange
            ? onChange
            : (option) => {
                setFieldValue(field.name, option?.value)
              }
        }
        value={
          options
            ? options.find((option) => {
                return option.value === field.value
              })
            : ''
        }
        placeholder={placeholder}
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        {...props}
        styles={clientStyles}
        menuPlacement="auto"
      />
      <ErrorMessage component="span" name={field.name} className="text-xs text-error" />
    </div>
  )
}

export default SelectInput
