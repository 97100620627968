import { t } from 'i18next'
import { useEffect } from 'react'
import 'tippy.js/dist/tippy.css'

import { useProtocolStatusQuery } from '../../../api/services/protocolApi'
import { ApproveCheckboxIcon, AwaitingIcon, RejectCheckboxIcon } from '../../../assets/icons'
import NewModal from '../../root/modals/NewModal'

const FileProcessModal = ({ open, onClose, fileId }) => {
  const { data: fileStatusData, refetch } = useProtocolStatusQuery(fileId, { skip: !fileId })

  // Refetch each time the modal opens
  useEffect(() => {
    if (open) refetch()
  }, [open])

  return (
    <NewModal showModal={open} setShowModal={onClose} onClickClose={onClose} title={t('process')} page="configStamp">
      {fileStatusData?.length < 1 ? (
        <div>
          <p>{t('process_info')}</p>
        </div>
      ) : (
        <ul className="flex flex-col gap-4 capitalize text-secondary">
          {fileStatusData?.map((person) => {
            if (person.status === 'approved' || person.status === 'approved/edited') {
              return (
                <ul className="flex gap-4 items-center">
                  <ApproveCheckboxIcon />
                  <p>{person.username}</p>
                </ul>
              )
            }
            if (person.status === 'awaiting') {
              return (
                <ul className="flex gap-4 items-center">
                  <AwaitingIcon />
                  <p>{person.username}</p>
                </ul>
              )
            }
            if (person.status === 'rejected' || person.status === 'rejected/edited') {
              return (
                <ul className="flex gap-4 items-center">
                  <RejectCheckboxIcon />
                  <p>{person.username}</p>
                </ul>
              )
            }
          })}
        </ul>
      )}
    </NewModal>
  )
}

export default FileProcessModal
