import React from 'react'

export const FileRelationCheckbox = React.forwardRef(
  ({ indeterminate, stampKey, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div className='flex justify-center items-center cursor-pointer w-full h-full'>
        <input
          type='checkbox'
          className='option-checkbox'
          id={stampKey}
          ref={resolvedRef}
          {...rest}
        />
        <label htmlFor={stampKey} className='option-label'></label>
      </div>
    )
  }
)
