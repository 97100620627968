import TableArchive from '../../components/table/files/TableArchive'

const ArchiveDocs = () => {
  return (
    <div className="w-full h-full">
      <TableArchive />
    </div>
  )
}

export default ArchiveDocs
