import React from 'react'

const CheckIcon = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.09091 9.49254L1.27273 5.73134L0 6.98507L5.09091 12L16 1.25373L14.7273 0L5.09091 9.49254Z"
        fill="#FCFCFC"
      />
    </svg>
  )
}

export default CheckIcon
