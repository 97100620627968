import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { useEditRelationMutation } from '../../../../api/services/documentRelations'
import { usePostPaginatedProtocolMutation } from '../../../../api/services/protocolApi'
import { useGetCustomStampsQuery } from '../../../../api/services/stampsApi'
import { FileRelationCheckbox } from '../../../../components/other/FileRelationCheckbox'
import CheckboxPaginatedTable from '../../../../components/table/CheckboxPaginatedTable'

const EditFileRelationsPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const company = useSelector((state) => state.company.company)
  // page & pageSize for pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const [protocolFiles, { data: ProtocolFiles }] = usePostPaginatedProtocolMutation()

  const data = useMemo(() => (Array.isArray(ProtocolFiles?.rows) ? ProtocolFiles?.rows : []), [ProtocolFiles])

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    return queryString
  }, [filterInputs])

  const noDisplay = data?.map((item) => {
    return item?.relatedFileId
  })

  const exclude = noDisplay?.length > 1 ? noDisplay : null

  useEffect(() => {
    protocolFiles({ page, pageSize, companyId: company?.id, query, exclude: exclude })
  }, [page, pageSize])

  const { data: stampFields } = useGetCustomStampsQuery({
    companyId: company?.id,
  })

  let subjectHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Predmet' || el.attributeKey === 'Broj fakture') {
      return el
    }
  })
  let clientHeader = stampFields?.find((el) => {
    if (
      el.attributeKey === 'Klijent' ||
      el.attributeKey === 'Partner' ||
      el.attributeKey === 'Dobavljač' ||
      el.attributeKey === 'Kupac'
    ) {
      return el
    }
  })
  let locationHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Mjesto' || el.attributeKey === 'Sjedište') {
      return el
    }
  })
  let dateHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Datum dokumenta' || el.attributeKey === 'Datum predmeta') {
      return el
    }
  })

  const editRelationColumns = [
    {
      Header: t('stamp_key'),
      accessor: 'Sifra',
    },
    {
      Header: subjectHeader?.attributeKey || t('subject'),
      accessor: 'Predmet',
    },
    {
      Header: clientHeader?.attributeKey || t('client'),
      accessor: 'Klijent',
    },
    {
      Header: locationHeader?.attributeKey || t('location'),
      accessor: 'Lokacija',
    },
    {
      Header: dateHeader?.attributeKey || t('date_of_prijem'),
      accessor: 'Datum prijema',
    },
    {
      Header: 'Status',
      accessor: 'Status',
      Cell: ({ row }) => {
        let status = row?.original.Status

        return status === 'Approved' ? t('approved') : status === 'Rejected' ? t('rejected') : t('awaiting')
      },
    },
    {
      Header: t('type'),
      accessor: 'Tip',
    },
    {
      Header: t('address'),
      accessor: 'address',
    },
    {
      Header: t('category'),
      accessor: 'Kategorije',
      Cell: ({ row }) => {
        let category = row.original.Kategorije
        if (!category) return 'Nema kategorije'
        return category
      },
    },
    {
      Header: t('kind'),
      accessor: 'advance',
      Cell: ({ row }) => {
        let advance = row.original.advance === 1 ? 'advance' : ''
        let importExport = row.original.importExport === 1 ? 'import' : 'export'
        let clasified = row.original.clasified === 1 ? 'clasified' : ''
        let reExport = row.original.reExport === 1 ? 're-export' : ''
        let KO = row.original.KO === 1 ? 'KO' : ''
        return (
          <div className="flex flex-col w-full justify-center items-center">
            <p className="mb-0">{advance}</p>
            <p className="mb-0">{importExport}</p>
            <p className="mb-0">{clasified}</p>
            <p className="mb-0">{KO}</p>
            <p className="mb-0">{reExport}</p>
          </div>
        )
      },
    },
  ]

  const attachedColumns = useMemo(() => editRelationColumns, [id])

  const [selectedFlatRows, setSelectedFlatRows] = useState()

  const [editRelation] = useEditRelationMutation()

  const handleEditRelation = (rowId) => {
    try {
      const relatedDocument = { id: +id, relatedFileId: rowId }

      editRelation(relatedDocument).unwrap()
      navigate(-1)
    } catch (error) {}
  }

  //checkbox selection
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: 'select',
        Header: t('select'),
        Cell: ({ row }) => (
          <div
            className="flex justify-center items-center cursor-pointer w-full h-full"
            onClick={() => {
              handleEditRelation(row.original.id)
            }}
          >
            <FileRelationCheckbox {...row.getToggleRowSelectedProps()} stampKey={row.original.Sifra} />
          </div>
        ),
      },
      ...columns,
    ])
  }

  return (
    <>
      <CheckboxPaginatedTable
        columns={attachedColumns}
        data={data}
        tableHooks={tableHooks}
        setSelectedRows={setSelectedFlatRows}
        hiddenColumns={['address', 'id']}
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={ProtocolFiles?.totalPages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={ProtocolFiles?.count}
        actions
        filtering
      />
    </>
  )
}

export default EditFileRelationsPage
