import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { setFileData } from '../../api/features/fileSlice'
import DocumentTypeRadioButton from '../inputs/DocumentTypeRadioButton'

const DocumentTypeForm = ({ setPageState }) => {
  const fileData = useSelector((state) => state.file.fileData)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const handleRecieved = () => {
    dispatch(
      setFileData({
        issued: false,
        inSlide: false,
        outSlide: false,
        koDoc: false,
        advanced: false,
        reExport: false,
        reciedved: !fileData.reciedved,
      }),
    )
  }
  const handleIssued = () => {
    dispatch(
      setFileData({
        issued: !fileData.issued,
        inSlide: false,
        outSlide: false,
        koDoc: false,
        advanced: false,
        reciedved: false,
        reExport: false,
      }),
    )
  }
  const handleImport = () => {
    dispatch(
      setFileData({
        issued: false,
        inSlide: !fileData.inSlide,
        outSlide: false,
        koDoc: false,
        advanced: false,
        reciedved: false,
        reExport: false,
      }),
    )
  }
  const handleExport = () => {
    dispatch(
      setFileData({
        issued: false,
        inSlide: false,
        outSlide: !fileData.outSlide,
        koDoc: false,
        advanced: false,
        reciedved: false,
        reExport: false,
      }),
    )
  }

  return (
    <div className="flex gap-4 border-b border-neutral">
      {/* Recieved */}
      <DocumentTypeRadioButton checked={fileData?.reciedved} onChange={handleRecieved} title={t('recieved')} />

      {/* Issued */}
      <DocumentTypeRadioButton checked={fileData?.issued} title={t('clasified')} onChange={handleIssued} />

      {/* Import  */}
      <DocumentTypeRadioButton checked={fileData?.inSlide} onChange={handleImport} title={t('stamp_import')} />

      {/* Export */}
      <DocumentTypeRadioButton title={t('stamp_export')} checked={fileData?.outSlide} onChange={handleExport} />
    </div>
  )
}

export default DocumentTypeForm
