import { Form, Formik } from 'formik'
import { t } from 'i18next'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../../api/services/configuratorApi'

function StampPrint() {
  const company = useSelector((state) => state.company.company)
  const dispatch = useDispatch()
  const { data: config } = useGetConfiguratorQuery(company?.id)

  const initialValues = {
    ...config,
  }

  const [updateConfig] = useEditConfiguratorMutation()

  const togglePrintFormat = async () => {
    try {
      const { num_key, ...updatedConfig } = config
      const newPrintFormat = config.printFormat === 'sticker' ? 'A4' : 'sticker'
      const response = await updateConfig({
        companyId: company?.id,
        updatedConfig: { ...updatedConfig, printFormat: newPrintFormat },
      }).unwrap()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={(values, { setSubmitting }) => {}}>
      {({ values }) => (
        <>
          <Form className="flex justify-between items-center w-full">
            <p className=" h-[20px] font-popins font-medium leading-[20px] text-center text-lg text-[#073C59]">
              {t('stamp_print_format')}
            </p>
          </Form>
          <div className="flex w-[245px] h-[29px] gap-[8px]">
            <label
              className={`border border-[#D5D9EC] font-popins font-normal leading-5 text-sm cursor-pointer w-[118px] h-[29px] rounded-[8px] flex justify-center items-center gap-4 p-2 ${config?.printFormat === 'A4' ? ' text-white bg-[#006EAB]' : 'bg-white text-[#140F0F]'}`}
            >
              <input
                autoComplete="off"
                type="radio"
                id="export"
                checked={config?.printFormat === 'A4'}
                className={`radio radio-xs checked:bg-white checked:radio-primary`}
                onChange={togglePrintFormat}
              />
              <p>{t('sticker')}</p>
            </label>

            <label
              className={`border border-[#D5D9EC] font-popins font-normal leading-5 text-sm cursor-pointer w-[118px] h-[29px] rounded-[8px] flex justify-center items-center gap-4 p-2 ${config?.printFormat === 'sticker' ? ' text-white bg-[#006EAB]' : 'bg-white text-[#140F0F]'}`}
            >
              <input
                autoComplete="off"
                type="radio"
                id="export"
                checked={config?.printFormat === 'sticker'}
                className={`radio radio-xs checked:bg-white checked:radio-primary`}
                onChange={togglePrintFormat}
              />
              <p>{t('A4')}</p>
            </label>
          </div>
        </>
      )}
    </Formik>
  )
}

export default StampPrint
