export function debounce(func, delay) {
  let timerId

  return function (...args) {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
/**
 * Capitalizes the first letter of a word.
 * @param word - The word to capitalize.
 * @returns The capitalized word.
 */
export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}
