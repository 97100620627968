import { api } from '../api'

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    approveFile: builder.mutation({
      query: ({ userId, fileId, comment }) => ({
        url: `/users/user-approval/?userId=${userId}&fileId=${fileId}`,
        body: { comment },
        method: 'PUT',
      }),
      invalidatesTags: ['Users', 'Files'],
    }),
    getUserFunctionOnSelectCompany: builder.query({
      query: ({ userId, companyId }) => ({
        url: `/users/get-functions-for-user/${userId}/${companyId}`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    userProtocol: builder.mutation({
      query: ({ userID, id }) => ({
        url: `/users/user-protocol/?userId=${userID}&fileId=${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Users'],
    }),
    userRejection: builder.mutation({
      query: ({ userId, fileId, comment }) => ({
        url: `/users/user-rejection/?userId=${userId}&fileId=${fileId}`,
        body: comment,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    registerUser: builder.mutation({
      query: (body) => ({
        url: `/users/register`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    getAllUsers: builder.query({
      query: ({ page, pageSize, query }) => ({
        url: `/users/get-all-users/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Users'],
    }),
    getCompanyUsers: builder.query({
      query: (companyId) => ({
        url: `/users/get-users/${companyId}`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getRecipients: builder.query({
      query: ({ companyId }) => ({
        url: `/users/get-recipients/${companyId}`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getRecipientsForEdit: builder.query({
      query: ({ fileId }) => ({
        url: `/users/get-recipients-for-approver-edit/${fileId}`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    editUser: builder.mutation({
      query: ({ userId, editedUsers }) => ({
        url: `/users/edit-user/${userId}`,
        body: editedUsers,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    editUserInCompany: builder.mutation({
      query: ({ companyId, userData, userId, groupId }) => ({
        url: `/users/edit-company-user/${companyId}/${userId}/${groupId}`,
        body: userData,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/users/delete-user/${userId}`,
        method: `DELETE`,
      }),
      invalidatesTags: ['Users'],
    }),
    registerUserForCompany: builder.mutation({
      query: ({ company, user }) => ({
        url: `/users/company-register/${company}`,
        body: user,
        method: 'POST',
      }),
      invalidatesTags: ['Users', 'Companies'],
    }),
    registerCompanyGroupId: builder.mutation({
      query: ({ company, userPermissions, user }) => ({
        url: `/users/company-register/${company}/?groupId=${userPermissions}`,
        body: user,
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    getDeletedUsers: builder.query({
      query: ({ page, pageSize, query }) => ({
        url: `/users/get-all-deleted-users/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Users'],
    }),
    restoreDeletedUsers: builder.mutation({
      query: (userId) => ({
        url: `/users/restore-user/${userId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Users'],
    }),
    signIn: builder.mutation({
      query: ({ username, password }) => ({
        url: '/users/login',
        body: { username, password },
        method: 'POST',
      }),
      invalidatesTags: ['Users'],
    }),
    userFunction: builder.query({
      query: ({ userId, companyId }) => ({
        url: `/users/get-functions-for-user/${userId}/${companyId}`,
        metod: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getUserGroups: builder.query({
      query: ({ companyId, userId }) => ({
        url: `/users/get-users-by-company/${companyId}/${userId}`,
        metod: 'GET',
      }),
      providesTags: ['Users'],
    }),
    checkUser: builder.query({
      query: ({ userId, companyId }) => ({
        url: `/users/check-if-user-warning/${userId}/${companyId}`,
      }),
      providesTags: ['Users'],
    }),
  }),
})

export const {
  useApproveFileMutation,
  useDeleteUserMutation,
  useEditUserInCompanyMutation,
  useEditUserMutation,
  useGetAllUsersQuery,
  useGetCompanyUsersQuery,
  useGetDeletedUsersQuery,
  useGetRecipientsQuery,
  useGetUserFunctionOnSelectCompanyQuery,
  useLazyGetUserGroupsQuery,
  useRegisterUserForCompanyMutation,
  useRegisterUserMutation,
  useRegisterCompanyGroupIdMutation,
  useRestoreDeletedUsersMutation,
  useSignInMutation,
  useUserFunctionQuery,
  useGetUserGroupsQuery,
  useLazyUserFunctionQuery,
  useUserProtocolMutation,
  useUserRejectionMutation,
  useCheckUserQuery,
  useGetRecipientsForEditQuery,
} = usersApi
