import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

const RejectCheckboxIcon = () => {
  return (
    <div className="bg-red-600 w-[14px] h-[14px] rounded-sm flex justify-center items-center">
      <MdOutlineClose className="text-white rounded-sm" />
    </div>
  )
}

export default RejectCheckboxIcon
