import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'

import { setAttachedDocuments } from '../../api/features/attachedDocuments'
import { DeleteAttachedDocModal, DocumentUpdateModal } from '../modals/modals'
import NewTable from '../table/NewTable'

const AttachedDocument = ({ setAttachedDocs, attachedDocs, stateLabel }) => {
  const dispatch = useDispatch()
  const [date2, setDate] = useState(null)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [doc, setDoc] = useState(false)
  const [index, setIndex] = useState()

  const onClose = () => {
    setOpenEdit(false)
    setOpenDelete(false)
  }

  const handleDelete = () => {
    const newArr = [...attachedDocs]
    newArr.splice(index, 1)
    setAttachedDocs(newArr)
    dispatch(setAttachedDocuments(newArr))
  }

  const data = useMemo(() => (Array.isArray(attachedDocs) ? attachedDocs : []), [attachedDocs])

  const columns = useMemo(
    () => [
      {
        Header: t('order_number'),
        accessor: 'rb',
        Cell: ({ row }) => {
          const id = parseFloat(row.id) + 1
          return <span>{id}</span>
        },
      },
      { Header: t('doc_name'), accessor: 'text' },
      { Header: t('label'), accessor: 'label' },
      { Header: t('date_of_submission'), accessor: 'parsedDate' },
    ],
    [],
  )

  const tableHooksArray = [
    {
      label: t('edit'),
      Icon: FaEdit,
      onClick: (row) => {
        setOpenEdit(true)
        setDoc(row.original)
        // setDate(row.original.parsedDate)
        setIndex(row.id)
      },
      disabled: false,
    },
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        setOpenDelete(true)
        setIndex(row?.id)
        // setCopmany(row.original)
      },
      disabled: false,
    },
  ]

  return (
    <div className="w-full pb-10">
      {attachedDocs?.length > 0 ? (
        <NewTable columns={columns} data={data} pagination={false} actions hooksArray={tableHooksArray} />
      ) : null}

      <DocumentUpdateModal
        setAttachedDocuments={setAttachedDocuments}
        setAttachedDocs={setAttachedDocs}
        attachedDocs={attachedDocs}
        setDate={setDate}
        onClose={onClose}
        label={stateLabel}
        index={index}
        date={date2}
        open={openEdit}
        doc={doc}
      />
      <DeleteAttachedDocModal
        open={openDelete}
        onClose={onClose}
        handleDelete={() => {
          handleDelete()
          onClose()
        }}
      />
    </div>
  )
}

export default AttachedDocument
