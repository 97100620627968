import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { useGetAllCompaniesNoPaginationQuery } from '../../../../api/services/companiesApi'
import { useGetAllFunctionsQuery } from '../../../../api/services/functionsApi'
import { useCreateGroupsMutation } from '../../../../api/services/groupsApi'
import { clientStyles } from '../../../../data/createStampSelectStyles'
import SelectInputRegular from '../../../inputs/SelectInputRegular'
import TextInputRegular from '../../../inputs/TextInputRegular'
import Option from '../../../other/SelectCheckbox'
import NewModal from '../../../root/modals/NewModal'

const AddGroupModal = ({ open, onClose }) => {
  const { t } = useTranslation()

  const [companyGroupID, setCompanyGroupID] = useState()
  const [functions, setFunctions] = useState([])
  const [grupName, setGrupName] = useState('')

  const { data: allCompanies } = useGetAllCompaniesNoPaginationQuery()
  const { data: allFunctionos } = useGetAllFunctionsQuery()
  const [createGroup] = useCreateGroupsMutation()

  const createCompanyGroup = async (group, companyId) => {
    try {
      const response = await createGroup({ companyId, group }).unwrap()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  //Select optinos
  const companiesOptions = allCompanies?.companies.map((company) => ({
    value: company.id,
    label: company.name.toString() || 'No label',
  }))

  let functionOptions = []

  allFunctionos?.forEach((fun, index) => {
    if (fun.name !== 'admin-dashboard') functionOptions.push({ value: index, label: fun.name })
  })

  //Subbmit group
  const handeGroupSubbmit = (e) => {
    e.preventDefault()
    const functionNames = functions?.map((f) => f.label)
    const group = { functions: functionNames, name: grupName }

    if (!grupName || !functionNames.length) return toast.error(t('input_all_fields'))

    createCompanyGroup(group, companyGroupID.value)
    setFunctions()
    setGrupName('')
    setCompanyGroupID()
    onClose(false)
  }

  return (
    <NewModal
      setShowModal={onClose}
      showModal={open}
      title
      page={'middle'}
      btns
      titleClose={t('save')}
      titleSubmit={t('create_group')}
      onClickSubmit={handeGroupSubbmit}
    >
      <form className="flex flex-col" onSubmit={handeGroupSubbmit}>
        <h1 className="text-[#0D5C8D] text-xl">{t('create_group')}</h1>
        <div className="flex flex-col gap-4 h-[61.5vh] overflow-y-hidden">
          <TextInputRegular
            type="text"
            name="name"
            value={grupName}
            placeholder={t('name')}
            onChange={(e) => setGrupName(e.target.value)}
          />

          <Select
            options={companiesOptions}
            styles={clientStyles}
            value={companyGroupID || null}
            placeholder={t('company_name')}
            onChange={(e) => setCompanyGroupID(e)}
          />

          <SelectInputRegular
            options={functionOptions}
            value={functions || null}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option }}
            placeholder={t('functions')}
            name="functions"
            isMulti
            onChange={(e) => setFunctions(e)}
          />
        </div>
      </form>
    </NewModal>
  )
}

export default AddGroupModal
