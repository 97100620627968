import React from 'react'

const FunctionCheckbox = ({ label, checked }) => {
  return (
    <div className="flex flex-row-reverse mb-2 w-full h-full">
      <p className="mb-0 ml-2 w-full text-lg text-[#073C59]">{label}</p>
      <input
        type="checkbox"
        id="invoice"
        name={label}
        checked={checked}
        readOnly
        className="checkbox checkbox-md border-[#A6A9AD] [--chkbg:theme(colors.white)] [--chkfg:black]"
      />
    </div>
  )
}

export default FunctionCheckbox
