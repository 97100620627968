import TableDeletedCompanies from '../../components/table/companies/TableDeletedCompanies'
import useDarkMode from '../../hooks/useDarkMode'

const ModifyDeletedCompanies = () => {
  useDarkMode()

  return <TableDeletedCompanies />
}

export default ModifyDeletedCompanies
