import React from 'react'

const CompanyIcon = () => {
  return (
    <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_519_9428)">
        <path
          d="M6 0C2.6875 0 0 2.6875 0 6V58C0 61.3125 2.6875 64 6 64H18V54C18 50.6875 20.6875 48 24 48C27.3125 48 30 50.6875 30 54V64H42C45.3125 64 48 61.3125 48 58V6C48 2.6875 45.3125 0 42 0H6ZM8 30C8 28.9 8.9 28 10 28H14C15.1 28 16 28.9 16 30V34C16 35.1 15.1 36 14 36H10C8.9 36 8 35.1 8 34V30ZM22 28H26C27.1 28 28 28.9 28 30V34C28 35.1 27.1 36 26 36H22C20.9 36 20 35.1 20 34V30C20 28.9 20.9 28 22 28ZM32 30C32 28.9 32.9 28 34 28H38C39.1 28 40 28.9 40 30V34C40 35.1 39.1 36 38 36H34C32.9 36 32 35.1 32 34V30ZM10 12H14C15.1 12 16 12.9 16 14V18C16 19.1 15.1 20 14 20H10C8.9 20 8 19.1 8 18V14C8 12.9 8.9 12 10 12ZM20 14C20 12.9 20.9 12 22 12H26C27.1 12 28 12.9 28 14V18C28 19.1 27.1 20 26 20H22C20.9 20 20 19.1 20 18V14ZM34 12H38C39.1 12 40 12.9 40 14V18C40 19.1 39.1 20 38 20H34C32.9 20 32 19.1 32 18V14C32 12.9 32.9 12 34 12Z"
          fill="#073C59"
        />
      </g>
      <defs>
        <clipPath id="clip0_519_9428">
          <rect width="48" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CompanyIcon
