import { useSelector } from 'react-redux'

import { useUserCompaniesQuery } from '../../api/services/companiesApi'
import CompaniesTabs from '../../components/companies/CompaniesTabs'
import TableAwaiting from '../../components/table/files/TableAwaiting'

const AwaitingDocs = () => {
  const user = useSelector((state) => state.user.user)
  const { data: userCompanies } = useUserCompaniesQuery(user?.id)

  return (
    <>
      {userCompanies?.length > 1 ? <CompaniesTabs /> : null}
      <TableAwaiting />
    </>
  )
}

export default AwaitingDocs
