import { t } from 'i18next'

const ColumnFilterPagination = ({ column, handleFilterChange, count }) => {
  const { filterValue, setFilter } = column

  return (
    <div>
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          handleFilterChange(e.target.value, column.id)
        }}
        placeholder={`${t('search_by')} ${column.Header}`}
        className="filter"
      />
    </div>
  )
}

export default ColumnFilterPagination
