import React from 'react'

const DeleteIcon = () => {
  return (
    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0H30C31.1063 0 32 0.89375 32 2V4C32 5.10625 31.1063 6 30 6H2C0.89375 6 0 5.10625 0 4V2C0 0.89375 0.89375 0 2 0ZM2 8H30V24C30 26.2062 28.2062 28 26 28H6C3.79375 28 2 26.2062 2 24V8ZM10 13C10 13.55 10.45 14 11 14H21C21.55 14 22 13.55 22 13C22 12.45 21.55 12 21 12H11C10.45 12 10 12.45 10 13Z"
        fill="#073C59"
      />
    </svg>
  )
}

export default DeleteIcon
