import { useState } from 'react'
import { useSelector } from 'react-redux'

import { LogoutModal } from '../components/modals/modals'
import Breadcrumbs from './Breadcrumbs'

const Navbar = () => {
  const [openLogut, setOpenLogut] = useState(false)

  const company = useSelector((state) => state.company?.company)

  return (
    <>
      <div className="w-full border-b-2 border-b-[rgba(0,,0,1px,0)] flex justify-between">
        <Breadcrumbs />
        <div>
          <p className="mb-0 w-full text-secondary font-extrabold breadcrumbs text-sm">{company?.name}</p>
        </div>
      </div>
      <LogoutModal open={openLogut} onClose={() => setOpenLogut(false)} />
    </>
  )
}

export default Navbar
