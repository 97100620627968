import { t } from 'i18next'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button } from '../../../../root/Button'

const AddAPIField = ({ onClick, spredValues, setInputFields, filteredOptions, index }) => {
  const addAPIField = (e) => {
    e.preventDefault()
    if (filteredOptions.lenght !== 0) {
      let newFields = [...spredValues]
      newFields.splice(index + 1, 0, {
        uId: uuidv4(),
        attributeKey: '',
        attributeValue: '',
        select: true,
        api: true,
        api_url: '',
      })

      setInputFields(newFields)
    } else {
      return
    }

    onClick()
  }

  return (
    <Button onClick={addAPIField} className="text-xl font-semibold">
      {t('api_field')}
    </Button>
  )
}

export default AddAPIField
