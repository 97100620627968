import { api } from "../api";

export const configuratorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getConfigurator: builder.query({
      query: (companyId) => ({
        url: `/configurator/get-configurator/${companyId}`,
      }),
      providesTags: ["Configurator"],
    }),
    editConfigurator: builder.mutation({
      query: ({ companyId, updatedConfig }) => ({
        url: `/configurator/edit-configurator/${companyId}`,
        method: "PUT",
        body: updatedConfig,
      }),
      invalidatesTags: ["Configurator"],
    }),
  }),
});

export const {
  useGetConfiguratorQuery,
  useLazyGetConfiguratorQuery,
  useEditConfiguratorMutation,
} = configuratorApi;
