import { api } from '../api'

export const protocolApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findDuplicatesProtocol: builder.query({
      query: ({ companyId, stampKey }) => ({
        url: `/protocol/find-duplicates/${companyId}/${stampKey}`,
      }),
      providesTags: ['Protocol'],
    }),
    getProtocolFiles: builder.query({
      query: (companyId) => ({
        url: `/protocol/protocol-book/${companyId}`, //ne koristi se vise
      }),
      providesTags: ['Protocol'],
    }),
    paginatedProtocol: builder.query({
      query: ({ companyId, page, pageSize, query }) => ({
        url: `/protocol/get-paginated-protocol-book/${companyId}/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Protocol', 'Files'],
    }),
    protocolStatus: builder.query({
      query: (fileId) => ({
        url: `/protocol/status/${fileId}`,
      }),
      providesTags: ['Protocol'],
    }),
    lastStampKey: builder.query({
      query: (companyId) => ({
        url: `/protocol/last-stamp-key/${companyId}`,
      }),
      providesTags: ['Protocol', 'Files', 'Configurator'],
    }),
    postPaginatedProtocol: builder.mutation({
      query: ({ companyId, page, pageSize, query, exclude }) => ({
        url: `/protocol/paginated-protocol-book/${companyId}/${page}/${pageSize}${query ? `/?${query}` : ''}`,
        method: 'POST',
        body: { exclude },
      }),
      providesTags: ['Protocol, Files'],
    }),
    protocolFilesLogs: builder.query({
      query: ({ companyId, page, pageSize, query }) => ({
        url: `/protocol/get-file-logs/${companyId}/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Protocol', 'Logs'],
    }),
    getProtocolComments: builder.query({
      query: ({ fileId }) => ({
        url: `/protocol/comments/${fileId}`,
      }),
      providesTags: ['Protocol'],
    }),
  }),
})

export const {
  useFindDuplicatesProtocolQuery,
  useGetProtocolFilesQuery,
  useLastStampKeyQuery,
  usePaginatedProtocolQuery,
  useLazyPaginatedProtocolQuery,
  useProtocolStatusQuery,
  useLazyFindDuplicatesProtocolQuery,
  useLazyLastStampKeyQuery,
  usePostPaginatedProtocolMutation,
  useProtocolFilesLogsQuery,
  useGetProtocolCommentsQuery,
} = protocolApi
