import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../api/services/configuratorApi'
import { buttonStamp } from '../../../data/stamp/btnStamp'
import NewModal from '../../root/modals/NewModal'

const DefaultStampPosition = ({ open, onClose }) => {
  const company = useSelector((state) => state.company.company)

  const { data: companyConfig } = useGetConfiguratorQuery(company?.id)

  const { t } = useTranslation()

  const [updateCompanyConfig] = useEditConfiguratorMutation()

  const stampPositionOptons = buttonStamp.map((btn) => {
    const valueObj = {
      value: [btn.cordX, btn.cordAllY, btn.cordRectX],
      label:
        btn.label === '1'
          ? t('top_left')
          : btn.label === '2'
            ? t('top_center')
            : btn.label === '3'
              ? t('top_right')
              : btn.label === '4'
                ? t('center_left')
                : btn.label === '5'
                  ? t('center')
                  : btn.label === '6'
                    ? t('center_right')
                    : btn.label === '7'
                      ? t('bottom_left')
                      : btn.label === '8'
                        ? t('bottom_center')
                        : t('bottom_right'),
    }
    return valueObj
  })

  const onPositionChange = (e) => {
    //Change to number to update stampPosition in configurator
    const sendValue =
      e.label === t('top_left')
        ? 1
        : e.label === t('top_center')
          ? 2
          : e.label === t('top_right')
            ? 3
            : e.label === t('center_left')
              ? 4
              : e.label === t('center')
                ? 5
                : e.label === t('center_right')
                  ? 6
                  : e.label === t('bottom_left')
                    ? 7
                    : e.label === t('bottom_center')
                      ? 8
                      : 9

    const newConfig = {
      ...companyConfig,
      position: parseInt(sendValue),
    }

    delete newConfig?.num_key

    updateCompanyConfig({
      companyId: company?.id,
      updatedConfig: newConfig,
    })

    toast.success(t('default_stamp_pod'))
    onClose()
  }

  return (
    <>
      <NewModal showModal={open} closeModal={onClose} hideClose title={t('set_stamp_position')}>
        <div className="p-6 h-96">
          <Select
            placeholder={t('stamp_position')}
            options={stampPositionOptons}
            onChange={(e) => onPositionChange(e)}
            className="w-full"
          />
        </div>
      </NewModal>
    </>
  )
}

export default DefaultStampPosition
