import React from 'react'

const SingleChevronIcon = () => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 1L1.2 5.8L6 10.6" stroke="#222222" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default SingleChevronIcon
