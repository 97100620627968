import { api } from '../api'

export const emailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      query: ({ users, stampId }) => ({
        url: `/email/send-email`,
        method: 'POST',
        body: { users, stampId },
      }),
      invalidatesTags: ['Email'],
    }),
  }),
})

export const { useSendEmailMutation } = emailApi
