import React from 'react'

import useGETAPI from '../../../hooks/createstamp/useGETAPI'
import SelectInput from '../SelectInput'

const APISelect = (props) => {
  const options = useGETAPI(props.api)

  return <SelectInput options={options} {...props} />
}

export default APISelect
