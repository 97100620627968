import { useParams } from 'react-router-dom'

import TableAttachments from '../../../../components/table/files/TableAttachments'

const AttachmentsPage = () => {
  const { id } = useParams()

  return <TableAttachments primaryDocumentId={id} />
}

export default AttachmentsPage
