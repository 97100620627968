import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useSelector } from 'react-redux'

import { useGetConfiguratorQuery } from '../../../api/services/configuratorApi'
import { useGetCustomStampsQuery, useGetStampFieldsForEditFileQuery } from '../../../api/services/stampsApi'
import NewModal from '../../root/modals/NewModal'

const PrintStampModal = ({ open, onClose, data, componentRef }) => {
  const [selectedStamp, setSelectedStamp] = useState([])
  const { t } = useTranslation()

  const company = useSelector((state) => state.company.company)

  const { data: fields } = useGetStampFieldsForEditFileQuery(
    { companyId: company?.id, fileId: data.fileId },
    {
      skip: !data.fileId || !company?.id,
    },
  )

  const { data: config } = useGetConfiguratorQuery(company?.id)
  const { data: companyStampFields } = useGetCustomStampsQuery({ companyId: company?.id })

  const useQRcode = config?.qr

  useEffect(() => {
    if (fields) {
      //Translateing and reducing to a desiered object
      let selectedStamp = []

      const translations = {
        'Stamp ID': 'Šifra pečata',
        Subject: 'Predmet',
        Client: 'Klijent',
        Location: 'Lokacija',
        Date: 'Datum',
        DateOfDocument: 'Datum dokumenta',
        'Organisational Unit': 'Organizaciona jedinica',
        Label: 'Oznaka',
        User: 'Protokolisao',
      }

      Object.entries(fields || {})?.forEach(([key, value], index) => {
        if (key !== 'undefined') {
          const attributeKey = translations[key] || key
          console.log(attributeKey, key)
          const myField = companyStampFields?.find((item) => item.attributeKey === key)

          if (key === 'Protokolisao' || key === 'Tip dokumenta') {
            selectedStamp[index] = { attributeKey, attributeValue: value, displayOnStamp: true }
          } else {
            selectedStamp[index] = { attributeKey, attributeValue: value, displayOnStamp: myField?.displayOnStamp }
          }
        }
      })

      setSelectedStamp(selectedStamp)
    }
  }, [fields])

  const stampToQR = selectedStamp?.map((obj) => [obj.attributeKey, obj.attributeValue])

  return (
    <>
      <NewModal
        showModal={open}
        setShowModal={onClose}
        title={'Print '}
        btns
        titleClose={t('close')}
        onClickClose={onClose}
        printStamp={true}
        componentRef={componentRef.current}
        useQRcode={useQRcode}
      >
        <div>
          <section className="usestamp-table">
            <table className="CS-62mm scale-[85%]" id="table" ref={componentRef}>
              {selectedStamp?.map((inputField, index) => {
                if (inputField.attributeKey === 'fileId') {
                  return
                }
                return (
                  <tbody key={index}>
                    <tr className="CS-th_th">
                      <th className="CS-th_th">{inputField.attributeKey}</th>
                      <td className="CS-td">{inputField.attributeValue}</td>
                    </tr>
                  </tbody>
                )
              })}
              {useQRcode && (
                <tbody>
                  <tr className="CS-th_th">
                    <th className="CS-th_th"></th>
                    <td className="CS-td">
                      <div>
                        <QRCode value={JSON.stringify(stampToQR)} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </section>

          {/* Print table */}
          <div className="hidden">
            <section className="usestamp-table">
              <table className="CS-62mm scale-[85%]" id="table" ref={componentRef}>
                {selectedStamp?.map((inputField, index) => {
                  if (inputField.attributeKey === 'fileId' || !inputField.displayOnStamp) {
                    return
                  }
                  return (
                    <tbody key={index}>
                      <tr className="CS-th_th">
                        <th className="CS-th_th">{inputField.attributeKey}</th>
                        <td className="CS-td">{inputField.attributeValue}</td>
                      </tr>
                    </tbody>
                  )
                })}
                {useQRcode && (
                  <tbody>
                    <tr className="CS-th_th">
                      <th className="CS-th_th"></th>
                      <td className="CS-td">
                        <div>
                          <QRCode value={JSON.stringify(stampToQR)} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </section>
          </div>
        </div>
      </NewModal>
    </>
  )
}

export default PrintStampModal
