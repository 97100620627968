const TableActionButton = ({ label, disabled, Icon, onClick, Image }) => {
  return (
    <li className="z-50">
      <button
        className={` text-secondary group flex gap-4 w-full items-center hover:bg-white hover:text-[#0D5C8D] rounded-md p-2 overflow-visible text-sm z-[99999]  disabled:cursor-not-allowed disabled:opacity-30`}
        onClick={onClick}
        disabled={disabled}
      >
        {Icon && (
          <div className="p-1 border-2 border-neutral rounded-lg">
            <Icon className="h-5 w-5" aria-hidden="true" />
          </div>
        )}
        {Image && (
          <div className="p-1 border-2 border-neutral rounded-lg">
            <img src={Image} alt="" className="w-5 h-5" />
          </div>
        )}

        {label}
      </button>
    </li>
  )
}

export default TableActionButton
