import React from 'react'

const SidebarLogoutLink = ({ link, openSidebar, setOpenLogut }) => {
  return (
    <li
      key={link.title}
      onClick={() => setOpenLogut(true)}
      className={`flex rounded-md cursor-pointer gap-4 hover:bg-white/10 text-white text-sm items-center ${link.title === 0 && 'bg-light-white'} ${!openSidebar ? 'w-fit' : 'w-4/5'} tooltip tooltip-right`}
      data-tip={link.title}
    >
      <p className="mb-0">{link.icon}</p>
      <span className={`${!openSidebar && 'hidden'} origin-left duration-200`}>{link.title}</span>
    </li>
  )
}

export default SidebarLogoutLink
