import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useDeleteGroupMutation } from '../../../../api/services/groupsApi'
import NewModal from '../../../root/modals/NewModal'

const DeleteGroupModal = ({ open, onClose, id }) => {
  const { t } = useTranslation()

  const [deleteGroup] = useDeleteGroupMutation()

  return (
    <NewModal
      closeModal={onClose}
      showModal={open}
      title={t('delete_group')}
      hideClose
      btns
      noChildren
      titleClose={t('close')}
      titleSubmit={t('delete')}
      onClickClose={onClose}
      onClickSubmit={async () => {
        try {
          const response = await deleteGroup(id).unwrap()
          toast.success(response.message)
          onClose()
        } catch (error) {
          toast.error(error.data.message)
        }
      }}
    />
  )
}

export default DeleteGroupModal
