import { t } from 'i18next'

import AddAPIField from './buttons/AddAPIField'
import AddInputField from './buttons/AddInputField'
import AddSelectField from './buttons/AddSelectField'

const ChooseInputModal = ({
  onClose,
  spredValues,
  nonSelectStampFields,
  setNonSelectStampFields,
  setInputFields,
  filteredOptions,
  index,
}) => {
  return (
    <div
      className={`flex flex-col w-[300px] justify-center items-center absolute ${index > 1 ? 'bottom-12 right-0' : 'top-12 right-0'} rounded-xl bg-white shadow-black/80 shadow-2xl pb-5 z-30`}
    >
      <button className="absolute top-2 right-2 h-4 w-4 text-center font-bold text-xl" onClick={onClose}>
        x
      </button>

      <div className={`h-4 w-4 bg-white absolute ${index > 1 ? '-bottom-2' : '-top-2'} right-3.5 rotate-45`}></div>
      <ul className="flex flex-col gap-2 w-4/5 pt-10">
        <label className="w-full text-center text-main-800 font font-semibold">{t('select_stamp_input_type')}</label>
        <AddInputField
          onClick={onClose}
          nonSelectStampFields={nonSelectStampFields}
          setNonSelectStampFields={setNonSelectStampFields}
          filteredOptions={filteredOptions}
          index={index}
        />
        <AddSelectField
          onClick={onClose}
          spredValues={spredValues}
          setInputFields={setInputFields}
          filteredOptions={filteredOptions}
          index={index}
        />
        <AddAPIField
          onClick={onClose}
          spredValues={nonSelectStampFields}
          setInputFields={setNonSelectStampFields}
          filteredOptions={filteredOptions}
          index={index}
        />
      </ul>
    </div>
  )
}

export default ChooseInputModal
