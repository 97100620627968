export const unacceptedFields = [
  'Status',
  'Kategorije',
  'Tip',
  'address',
  'address_no_stamp',
  'id',
  'fileName',
  'note',
  'issued',
  'importOption',
  'exportOption',
  'reExport',
  'advance',
  'KO',
  'booked',
  'isDeleted',
  'typeId',
  'categoryId',
  'statusId',
  'folder',
  'uploaderId',
  'createdAt',
  'updatedAt',
  'companyId',
  'clientId',
  'comments',
  'received',
]
