import React from 'react'

const AwaitingIcon = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_188_9187)">
        <path
          d="M1 0C0.446875 0 0 0.446875 0 1C0 1.55313 0.446875 2 1 2V2.34375C1 3.66875 1.52813 4.94063 2.46563 5.87813L4.58437 8L2.46563 10.1219C1.52813 11.0594 1 12.3313 1 13.6562V14C0.446875 14 0 14.4469 0 15C0 15.5531 0.446875 16 1 16H2H10H11C11.5531 16 12 15.5531 12 15C12 14.4469 11.5531 14 11 14V13.6562C11 12.3313 10.4719 11.0594 9.53438 10.1219L7.41563 8L9.5375 5.87813C10.475 4.94063 11.0031 3.66875 11.0031 2.34375V2C11.5563 2 12.0031 1.55313 12.0031 1C12.0031 0.446875 11.5563 0 11.0031 0H10H2H1ZM9 13.6562V14H3V13.6562C3 12.8594 3.31562 12.0969 3.87812 11.5344L6 9.41562L8.12187 11.5375C8.68437 12.1 9 12.8625 9 13.6594V13.6562Z"
          fill="#073C59"
        />
      </g>
      <defs>
        <clipPath id="clip0_188_9187">
          <rect width="12" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AwaitingIcon
