import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowRight } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useAsyncDebounce } from 'react-table'

import { useProtocolFilesLogsQuery } from '../../../api/services/protocolApi'
import SelectColumnFilterPaginated from '../components/SelectColumnFilterPaginated'
import NewTable from '../NewTable'
import TableWrapper from '../TableWrapper'

const TableLogs = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const company = useSelector((state) => state.company.company)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (key === 'Date') {
        if (value.trim() !== '') {
          const formattedDate = format(new Date(value), 'dd-MM-yyyy')
          queryString += `Date=${encodeURIComponent(formattedDate)}&`
        }
      } else {
        if (value.trim() !== '') {
          queryString += `${key}=${encodeURIComponent(value)}&`
        }
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  // const { data: Logs } = useGetLogsQuery({ companyId: company?.id, page, pageSize, query: query ? query : '' })
  const { data: Logs } = useProtocolFilesLogsQuery(
    {
      companyId: company?.id,
      page: page,
      pageSize: pageSize,
      query: query ? query : '',
    },
    { refetchOnMountOrArgChange: true },
  )

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('stamp_key'),
        accessor: 'new_stampKey',
      },
      {
        Header: t('subject'),
        accessor: 'subject',
      },
      {
        Header: t('client'),
        accessor: 'client',
      },
      {
        Header: t('category'),
        accessor: 'categoryName',
        Cell: ({ row }) => {
          return (
            <div className="w-full flex justify-betwee">
              {row.original.categoryName === row.original.newCategoryName ? (
                <div className="bg-primary text-white rounded-lg h-full w-full">{t('no_changes')}</div>
              ) : (
                <div className="flex justify-center  w-full items-center">
                  <div className="w-full h-full bg-red-600 text-white font-thin rounded-lg">
                    {row.original.categoryName}
                  </div>
                  <span className="">{<FaArrowRight className="w-3 h-3" />}</span>
                  <div className="w-full h-full bg-red-600 text-white font-thin rounded-lg">
                    {row.original.newCategoryName}
                  </div>
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: t('type'),
        accessor: 'typeName',
        Cell: ({ row }) => {
          return (
            <div className="w-full flex justify-between">
              {row.original.typeName === row.original.newTypeName ? (
                <div className="bg-primary text-white font-thin rounded-lg h-full w-full">{t('no_changes')}</div>
              ) : (
                <div className="flex justify-center  w-full items-center">
                  <div className="w-full h-full bg-red-600 text-white font-thin rounded-lg">
                    {row.original.typeName}
                  </div>
                  <span className="">{<FaArrowRight className="w-3 h-3" />}</span>
                  <div className="w-full h-full bg-main-green text-white font-thin rounded-lg">
                    {row.original.newTypeName}
                  </div>
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'statusName',
        Cell: ({ row }) => {
          return (
            // <div className="w-full h-full text-sm">
            //   {row.original.statusId === row.original.new_statusId ? (
            //     <div className="bg-primary h-full w-full">{t("no_changes")}</div>
            //   ) : (
            //     <div className="flex flex-col">
            //       <div className="bg-red-200">
            //         {row.original.statusName === 'Protocol' ? 'Awaiting' : row.original.statusName}
            //       </div>
            //       <span className="w-full  flex justify-center">{<FaArrowDown className="w-3 h-3" />}</span>
            //       <div className="bg-green-300">
            //         {row.original.newStatusName === 'Protocol' ? 'Awaiting' : row.original.newStatusName}
            //       </div>
            //     </div>
            //   )}
            // </div>
            <div className="w-full flex justify-betwee">
              {row.original.statusId === row.original.new_statusId ? (
                <div className="bg-primary text-white rounded-lg font-thin h-full w-full">{t('no_changes')}</div>
              ) : (
                <div className="flex justify-center w-full items-center">
                  <div className="w-full h-full bg-red-600 text-white font-thin rounded-lg">
                    {' '}
                    {row.original.statusName === 'Protocol' ? 'Awaiting' : row.original.statusName}
                  </div>
                  <span className="">{<FaArrowRight className="w-3 h-3" />}</span>
                  <div className="w-full h-full bg-main-green rounded-lg text-white font-thin ">
                    {row.original.newStatusName === 'Protocol' ? 'Awaiting' : row.original.newStatusName}
                  </div>
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: t('kind'),
        accessor: 'noaccessor',
        Cell: ({ row }) => {
          let change =
            row.original.importOption === 1
              ? 'Import'
              : row.original.exportOption === 1
                ? 'Export'
                : row.original.reExport === 1
                  ? 'Re-Export'
                  : row.original.received === 1
                    ? 'Received'
                    : row.original.issued === 1
                      ? 'Issued'
                      : row.original.KO === 1
                        ? 'KO'
                        : row.original.advance === 1
                          ? 'Advance'
                          : ''

          let change2 =
            row.original.new_importOption === 1
              ? 'Import'
              : row.original.new_exportOption === 1
                ? 'Export'
                : row.original.new_reExport === 1
                  ? 'Re-Export'
                  : row.original.new_received === 1
                    ? 'Received'
                    : row.original.new_issued === 1
                      ? 'Issued'
                      : row.original.new_KO === 1
                        ? 'KO'
                        : row.original.new_advance === 1
                          ? 'Advance'
                          : ''

          return (
            // <div className="w-full h-full text-sm">
            //   {change === change2 ? (
            //     <div className="bg-blue-200 h-full w-full">{t("no_changes")}</div>
            //   ) : (
            //     <div className="flex flex-col">
            //       <div className="bg-red-200">{change}</div>
            //       <span className="w-full  flex justify-center">{<FaArrowDown className="w-3 h-3" />}</span>
            //       <div className="bg-green-300">{change2}</div>
            //     </div>
            //   )}
            // </div>
            <div className="w-full flex justify-between  rounded-sm">
              {change === change2 ? (
                <div className="bg-primary text-white rounded-lg font-thin h-full w-full">{t('no_changes')}</div>
              ) : (
                <div className="flex justify-center w-full items-center">
                  <div className="w-full h-full bg-red-600 text-white font-thin rounded-lg">{change}</div>
                  <span className="">{<FaArrowRight className="w-3 h-3" />}</span>
                  <div className="w-full h-full bg-red-600 text-white font-thin rounded-lg">{change2}</div>
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: t('protocoled_at'),
        accessor: 'createdAt',
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt
          if (!createdAt) return ''
          const date = createdAt.substr(0, 10)
          let year = date.slice(0, 4)
          let month = date.slice(5, 7)
          let day = date.slice(8, 10)
          const time = createdAt.substr(11, 5).startsWith(0) ? createdAt.substr(12, 4) : createdAt.substr(11, 5)
          if (time.length === 5) {
            let hours = parseInt(time.substr(0, 2)) + 2
            let mins = time.substr(3)
            return `${day}-${month}-${year} ${hours}:${mins}`
          } else {
            let hours = parseInt(time.substr(0, 1)) + 2
            let rest = time.substr('2')
            return `${day}-${month}-${year} ${hours}:${rest}`
          }
        },
      },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Cell: ({ row }) => {
      //     const statusValue = parseInt(row.original.status)
      //     if (statusValue === 1) return t('approved')
      //     if (statusValue === 2) return t('rejected')
      //     if (statusValue !== 1 && statusValue !== 2) return t('awaiting')
      //   },
      //   Filter: ({ column }) => {
      //     return (
      //       <SelectColumnFilterPaginated
      //         column={column}
      //         handleFilterChange={handleFilterChange}
      //         advancedOptions
      //         providedOptions={[
      //           { label: t('approved'), value: 1 },
      //           { label: t('rejected'), value: 2 },
      //           { label: t('awaiting'), value: 3 },
      //         ]}
      //       />
      //     )
      //   },
      // },
      {
        Header: t('edited_at'),
        accessor: 'dateOfApproval',
        Cell: ({ row }) => {
          const dateOfApproval = row.original.dateOfApproval
          const date = dateOfApproval?.substr(0, 10)
          let year = date?.slice(0, 4)
          let month = date?.slice(5, 7)
          let day = date?.slice(8, 10)
          const time = dateOfApproval?.substr(11, 5).startsWith(0)
            ? dateOfApproval?.substr(12, 4)
            : dateOfApproval?.substr(11, 5)
          if (!dateOfApproval) return t('no_changes')
          if (time.length === 5) {
            let hours = parseInt(time.substr(0, 2)) + 2
            let mins = time.substr(3)
            return `${day}-${month}-${year} ${hours}:${mins}`
          } else {
            let hours = parseInt(time.substr(0, 1)) + 2
            let rest = time.substr('2')
            return `${day}-${month}-${year} ${hours}:${rest}`
          }
        },
      },
      {
        Header: t('edited_by'),
        accessor: 'approverUser',
        Cell: ({ row }) => {
          const user = row.original.approverUser
          if (!user) return t('no_changes')
          return user
        },
      },
      {
        Header: t('comment'),
        accessor: 'comment',
        Cell: ({ row }) => {
          const comment = row.original.comment
          if (comment) {
            return comment
          } else {
            return t('no_comment')
          }
        },
      },
      {
        Header: t('protocoled_by'),
        accessor: 'user',
      },
    ],
    [],
  )

  const data = useMemo(() => (Array.isArray(Logs?.protocolLogsQuery) ? Logs?.protocolLogsQuery : []), [Logs])

  return (
    <TableWrapper>
      <NewTable
        data={data}
        columns={columns}
        setPage={setPage}
        pageManual={page}
        filtering
        actions={false}
        setPageAmount={setPageSize}
        controlledPageCount={Logs?.totalPages || 0}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        count={Logs?.totalCount}
        hiddenColumns={['id', 'address']}
      />
    </TableWrapper>
  )
}
export default TableLogs
