import { t } from 'i18next'

const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column

  return (
    <span>
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value)
        }}
        placeholder={`${t('search_by')} ${column.Header}`}
        className="filter"
      />
    </span>
  )
}

export default ColumnFilter
