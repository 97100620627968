export const buttonStamp = [
  {
    label: '1',
    cordX: 11,
    cordRectX: 111,
    cordAllY: 630,
  },
  {
    label: '2',
    cordX: 206,
    cordRectX: 306,
    cordAllY: 630,
  },
  {
    label: '3',
    cordX: 381,
    cordRectX: 481,
    cordAllY: 630,
  },
  {
    label: '4',
    cordX: 11,
    cordRectX: 111,
    cordAllY: 360,
  },
  {
    label: '5',
    cordX: 206,
    cordRectX: 306,
    cordAllY: 360,
  },
  {
    label: '6',
    cordX: 381,
    cordRectX: 481,
    cordAllY: 360,
  },
  {
    label: '7',
    cordX: 11,
    cordRectX: 111,
    cordAllY: 30,
  },
  {
    label: '8',
    cordX: 206,
    cordRectX: 306,
    cordAllY: 30,
  },
  {
    label: '9',
    cordX: 381,
    cordRectX: 481,
    cordAllY: 30,
  },
]
