import { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'

import AddGroupModal from '../../components/modals/companies/company/AddGroupModal'
import { CreateCompanyModal } from '../../components/modals/modals'
import TableCompanies from '../../components/table/companies/TableCompanies'

const ModifyCompanies = () => {
  const [openCompany, setOpenCompany] = useState(false)
  const [openGroup, setOpenGroup] = useState(false)

  return (
    <>
      <TableCompanies setOpenCompany={() => setOpenCompany(true)} setOpenGroup={() => setOpenGroup(true)} />
      <CreateCompanyModal onClose={() => setOpenCompany(false)} open={openCompany} />
      <AddGroupModal onClose={() => setOpenGroup(false)} open={openGroup} />
    </>
  )
}

export default ModifyCompanies
