import React from 'react'

import SelectInput from '../SelectInput'

const VATSelect = (props) => {
  return (
    <SelectInput
      options={[
        { label: '17%', value: '17%' },
        { label: '0%', value: '0%' },
      ]}
      {...props}
    />
  )
}

export default VATSelect
