import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useGetAllFunctionsQuery, useUpdateGroupMutation } from '../../../api/services/functionsApi'
import FunctionCheckbox from '../../other/FunctionCheckbox'
import NewModal from '../../root/modals/NewModal'

const UpdateGroupModal = ({
  setNewGroupFunctions, //ne diraj
  newGroupFunctions, //ne diraj
  setNewGroupName, // ne diraj
  newGroupName, //ne diraj
  onClose,
  group,
  open,
}) => {
  const { t } = useTranslation()

  const { data: groupFunctions } = useGetAllFunctionsQuery()
  const [updateGroup] = useUpdateGroupMutation()
  const functionOptions = groupFunctions?.map((fun) => ({
    id: fun.id,
    name: fun.name,
  }))
  //Handle submit
  const handleUpdateGropup = async (e) => {
    e.preventDefault()

    if (!newGroupFunctions.length || !newGroupName.length) {
      return toast.error(t('input_all_fields'))
    }

    try {
      const response = await updateGroup({
        groupId: group.id,
        functionsId: newGroupFunctions?.map((fun) => fun.id),
      }).unwrap()

      onClose()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const handleChange = (fun) => {
    let includes = false
    newGroupFunctions.forEach((funObj) => {
      if (funObj.name === fun.name) includes = true
    })
    if (includes) {
      const newFunctions = [...newGroupFunctions]
      let index = newFunctions.findIndex((funObj) => funObj.name === fun.name)
      newFunctions.splice(index, 1)
      setNewGroupFunctions(newFunctions)
    } else {
      setNewGroupFunctions((prev) => [...prev, fun])
    }
  }

  return (
    <NewModal
      closeModal={onClose}
      showModal={open}
      setShowModal={onClose}
      title={t('edit_group')}
      btns
      titleClose={t('close')}
      titleSubmit={t('save')}
      onClickClose={onClose}
      onClickSubmit={handleUpdateGropup}
    >
      <>
        <form className="justify-center">
          <div className="w-full">
            <div className="flex flex-col w-full mb-4">
              <input
                type="text"
                name="name"
                value={newGroupName}
                placeholder={t('name')}
                className="w-full h-[48px] rounded-lg border-[1px] border-[#E6E6E5] py-[9px] px-[8px] placeholder:text-[#073C59] font-popins font-normal text-sm leading-5"
                onChange={(e) => setNewGroupName(e.target.value)}
                disabled
              />
            </div>
            {/* Funcions select */}
            <label className="font-popins font-semibold text-base leading-6 text-center text-[#073C59]">
              {t('choose_functions')}
            </label>

            <div className="w-full grid grid-cols-2 grid-rows-4 mt-3">
              {functionOptions?.map((fun, index) => {
                let includes = false
                newGroupFunctions.forEach((funObj) => {
                  if (fun.name === funObj.name) includes = true
                })
                if (fun.name === 'admin-dashboard') return
                if (fun.name === 'selectcompany') return
                return (
                  <button
                    className="text-left"
                    key={index}
                    onClick={(e) => {
                      e.preventDefault()
                      handleChange(fun)
                    }}
                  >
                    <FunctionCheckbox label={fun.name} checked={includes} />
                  </button>
                )
              })}
            </div>
          </div>
        </form>
      </>
    </NewModal>
  )
}

export default UpdateGroupModal
