import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useRestoreCompanyMutation } from '../../../../api/services/companiesApi'
import NewModal from '../../../root/modals/NewModal'

const RestoreDeletedCompany = ({ open, onClose, companyId }) => {
  const { t } = useTranslation()
  const [restoreDeletedCompany] = useRestoreCompanyMutation(companyId)

  const handleRestoreCompany = async () => {
    try {
      const response = await restoreDeletedCompany(companyId).unwrap()
      toast.success(response.message)
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <NewModal
      showModal={open}
      closeModal={onClose}
      hideClose
      title={t('you_sure_company_resore')}
      btns
      noChildren
      titleClose={t('close')}
      titleSubmit={t('restore')}
      onClickClose={onClose}
      onClickSubmit={handleRestoreCompany}
    />
  )
}

export default RestoreDeletedCompany
