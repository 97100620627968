import bs from 'date-fns/locale/bs'
import { useEffect, useMemo } from 'react'
import { registerLocale } from 'react-datepicker'
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import Spinner from '../other/Spinner'
import ColumnFilterPagination from './ColumnFilterPagination'
import PrefetchPagination from './components/PrefetchPagination'

registerLocale('bs', bs)

const CheckboxPaginatedTable = ({
  columns,
  data,
  isLoading,
  isFetching,
  setSelectedRows,
  tableHooks,
  filtering,
  navbar = true,
  caption = '',
  hiddenColumns,
  // Pagination
  controlledPageCount,
  setPage,
  pageManual,
  setPageAmount,
  count,
  filterInputs,
  setFilterInputs,
  skipPageReset,
}) => {
  //FILTERS
  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 800)

  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column }) => (
        <ColumnFilterPagination column={column} handleFilterChange={handleFilterChange} count={count} />
      ),
    }),
    [filterInputs, handleFilterChange, count],
  )

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualFilters: true,
      pageCount: controlledPageCount,
      autoResetSelectedRows: skipPageReset,
      defaultColumn,
      initialState: { pageSize: 25, hiddenColumns: hiddenColumns || '' },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    tableHooks,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageSize },
    setPageSize,
    pageCount,
    selectedFlatRows,
  } = tableInstance

  useEffect(() => {
    setSelectedRows(selectedFlatRows)
  }, [selectedFlatRows])

  return (
    <div className="drop-shadow-[0px_0px_3px_rgba(0,0,0,0.25)] pt-1">
      <div className="flex items-end justify-between">
        {navbar === true && (
          <PrefetchPagination
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageOptions={pageOptions}
            pageCount={pageCount}
            setPage={setPage}
            setPageAmount={setPageAmount}
            pageManual={pageManual}
          />
        )}
      </div>
      <div className={`w-full max-h-[80vh] overflow-auto max-w-full`}>
        <table {...getTableProps()} className={`text-xs border border-main-400 w-full z-0`}>
          <caption className="text-center font-bold uppercase">{caption}</caption>
          <thead className="p-2 sticky top-0 bg-main-regular">
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()} className="bg-main-400 text-main-300 sticky z-0">
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                        textAlign: 'center',
                        borderBottom: column.borderBottom,
                        fontSize: '13px',
                        padding: '14px',
                      },
                    })}
                    className="p-2 border border-t-main-400  border-b-main-400  border-l-main-300  text-sm font-semibold border-r-main-300 first-of-type:border-l-main-400 last-of-type:border-r-main-400 text-secondary tracking-wide"
                  >
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                      </span>
                    </div>

                    {filtering && (
                      <div className="text-sky-900">{column.canFilter ? column.render('Filter') : null}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...row.getRowProps()}
                  className={`bg-main-gray-ligth text-secondary tracking-wide text-center border-t-[2px] border-t-white ${
                    isFetching ? 'text-gray-300' : ''
                  }  `}
                >
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                            maxWidth: 100,
                            paddingTop: 10,
                            paddingBottom: 10,
                          },
                        })}
                        className="p-1"
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {isLoading && (
        <div className="flex justify-center items-center mt-5">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default CheckboxPaginatedTable
