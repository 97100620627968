import { Form, Formik } from 'formik'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { useFarwardFileMutation } from '../../../api/services/filesApi'
import { useGetRecipientsForEditQuery } from '../../../api/services/usersApi'
import { Button } from '../../root/Button'
import NewModal from '../../root/modals/NewModal'

const FarwardFileModal = ({ isOpen, onClose, id }) => {
  const { user: userObj } = useSelector((state) => state.user)

  const [farwardUser, { isLoading: loadingFarward }] = useFarwardFileMutation()

  const { data: companyUsers } = useGetRecipientsForEditQuery({ fileId: id }, { skip: !id })

  const userOptions =
    companyUsers
      ?.map((user) => ({ value: user.id, label: user.firstName + ' ' + user.lastName, id: user.id }))
      ?.filter((item) => item.label !== userObj.firstName) || []

  const initialValues = { userId: userObj.id, fileId: id, newUserId: '' }

  const onCloseModal = () => {
    onClose(false)
  }

  const handleFarward = async (values) => {
    try {
      const res = await farwardUser(values).unwrap()

      toast.success(res.message)
      onCloseModal()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <NewModal showModal={isOpen} setShowModal={onCloseModal} title={t('forward_doc_action')} page="configStamp">
      <Formik onSubmit={handleFarward} initialValues={initialValues}>
        {({ values, setFieldValue }) => {
          return (
            <Form className="flex flex-col gap-4 pb-20">
              <Select
                options={userOptions}
                styles={{
                  menuList: (base) => ({
                    ...base,
                    maxHeight: 150,
                    overflowX: 'auto',
                  }),
                }}
                name="newUserId"
                placeholder={t('receivers').slice(0, -1)}
                value={userOptions?.find((user) => user.value === values['newUserId']) || null}
                onChange={(option) => setFieldValue('newUserId', option.value)}
              />
              <Button asDiv className="w-full text-lg" disabled={loadingFarward}>
                {t('forward_doc_action')}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </NewModal>
  )
}

export default FarwardFileModal
