import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useDeleteCategoryMutation } from '../../../../api/services/categoriesApi'
import NewModal from '../../../root/modals/NewModal'

const DeleteCategoryModal = ({ open, onClose, category }) => {
  const { t } = useTranslation()

  const [deleteCategory] = useDeleteCategoryMutation()

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        hideClose
        title={t('you_sure_category')}
        btns
        titleClose={t('close')}
        titleSubmit={t('delete')}
        onClickClose={onClose}
        noChildren
        onClickSubmit={async () => {
          try {
            const response = await deleteCategory(category.id).unwrap()
            toast.success(response.message)
            onClose()
          } catch (error) {
            toast.error(error.data.message)
          }
        }}
      />
    </>
  )
}

export default DeleteCategoryModal
