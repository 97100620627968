import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { breadcrumbsConstants } from '../data/breadcrumbsConstants'
import { capitalizeFirstLetter } from '../utils/index'

const Breadcrumbs = () => {
  const location = useLocation()

  let currentLink = ''

  const replaceHyphensAndUnderscoresWithSpaces = (str) => {
    return str.replace(/[-_]/g, ' ') // Using regular expression with 'g' flag to replace all occurrences
  }

  const crumbs = location.pathname
    .split('/')
    .filter((el) => el !== '')
    .map((crumb) => {
      let translatedCrumb = replaceHyphensAndUnderscoresWithSpaces(crumb)
      breadcrumbsConstants.map((el) => {
        if (el.label === crumb) {
          return (translatedCrumb = el.value)
        }
      })
      currentLink += `/${crumb}`

      return (
        <li key={crumb}>
          <Link to={currentLink}>{decodeURIComponent(capitalizeFirstLetter(translatedCrumb))}</Link>
        </li>
      )
    })

  return (
    <div className="text-sm breadcrumbs font-extrabold text-secondary">
      <ul>{crumbs}</ul>
    </div>
  )
}

export default Breadcrumbs
