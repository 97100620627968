import React from 'react'

import { RIFoptions } from '../../../data/stamp/RIFdata'
import SelectInput from '../SelectInput'

const RIFSelect = (props) => {
  return <SelectInput options={RIFoptions} isMulti {...props} />
}

export default RIFSelect
