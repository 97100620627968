import { Dialog, Transition } from '@headlessui/react'
import { t } from 'i18next'
import React, { Fragment } from 'react'
import { AiOutlineLeftCircle, AiOutlineRightCircle } from 'react-icons/ai'
import ReactToPrint from 'react-to-print'

import Spinner from '../../other/Spinner'

const NewModal = ({
  children,
  title,
  header,
  page,
  showModal = false,
  setShowModal,
  setShowButton,
  initialFocus,
  closeOutside = false,
  showSelectOptions = false,
  titleClose,
  titleSubmit,
  disableSubmit = false,
  btns = false,
  onClickClose,
  onClickSubmit,
  displayBtns = false,
  onRightClick,
  onLeftClick,
  disabledLeft,
  disabledRight,
  useQRcode,
  printStamp,
  componentRef,
  hideClose,
  noChildren,
  navigation,
  handleChangePage,
  handleChangeBack,
  isLoading,
}) => {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 bg-slate-800/50 font-figtree z-50"
        onClose={() => ''}
        initialFocus={initialFocus}
        open={showModal}
      >
        <div className="min-h-screen px-4 text-center relative flex items-center justify-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {displayBtns && (
            <button
              className="z-[90] absolute top-[50%] left-[10%] bg-white mr-4 rounded-full disabled:cursor-not-allowed"
              disabled={disabledLeft}
              onClick={onLeftClick}
            >
              <AiOutlineLeftCircle size={40} />
            </button>
          )}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`${
                page === 'protocol'
                  ? 'max-h-[100vh]  max-w-[70%] overflow-y-hidden'
                  : page === 'Table'
                    ? 'max-h-[100vh] max-w-[90vw] overflow-y-hidden'
                    : page === 'middle'
                      ? 'max-w-xl'
                      : page === 'full'
                        ? 'max-w-2xl'
                        : page === 'configStamp'
                          ? 'max-w-[500px] max-h-[100vh]'
                          : page === 'w-xl'
                            ? 'max-w-xl'
                            : page === 'report'
                              ? 'max-w-6xl max-h-screen my-1'
                              : 'max-w-md'
              } custom-modal-content w-full max-w-7xl inline-block my-8 p-0.5 h-full transition-all max-h-screen rounded-b-[8px] transform bg-white shadow-xl rounded-t-[8px] ${btns ? 'rounded-b-[8px]' : ''}`}
            >
              {title && (
                <Dialog.Title
                  as="h3"
                  className="flex items-center justify-between py-[8px] px-[16px] rounded-t-[8px] text-xl font-semibold leading-6 text-[#073C59] bg-[#F7F7F7] border-b-[1px] border-[#D5D9EC]"
                >
                  {title}
                  <p>{header}</p>
                  {!hideClose && (
                    <span
                      onClick={() => {
                        setShowModal(false)
                        if (setShowButton) setShowButton(true)
                      }}
                      className="hover:text-red-400 hover:cursor-pointer"
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.46875 5.46875C5.7625 5.175 6.2375 5.175 6.52812 5.46875L7.99687 6.9375L9.46562 5.46875C9.75937 5.175 10.2344 5.175 10.525 5.46875C10.8156 5.7625 10.8187 6.2375 10.525 6.52812L9.05625 7.99687L10.525 9.46562C10.8187 9.75937 10.8187 10.2344 10.525 10.525C10.2312 10.8156 9.75625 10.8187 9.46562 10.525L7.99687 9.05625L6.52812 10.525C6.23438 10.8187 5.75938 10.8187 5.46875 10.525C5.17812 10.2312 5.175 9.75625 5.46875 9.46562L6.9375 7.99687L5.46875 6.52812C5.175 6.23438 5.175 5.75938 5.46875 5.46875Z"
                          fill="#073C59"
                        />
                      </svg>
                    </span>
                  )}
                </Dialog.Title>
              )}
              {!noChildren && (
                <div className={`overflow-y-auto ${btns ? 'max-h-[70vh]' : 'max-h-[85vh]'} p-4 custom-modal-content`}>
                  {children}
                </div>
              )}
              {btns && (
                <div className="flex justify-between items-center gap-[10px] p-4">
                  {titleClose && !navigation && (
                    <button
                      className="border-[1px] border-[#006EAB] py-[12px] text-[#073C59] px-[20px] w-full rounded-[4px] flex justify-center items-center bg-[#FCFCFC]"
                      onClick={onClickClose}
                    >
                      {titleClose}
                    </button>
                  )}
                  {titleSubmit && !navigation && (
                    <button
                      className="border-[1px] border-[#006EAB] py-[12px] text-[#FCFCFC] px-[20px] w-full rounded-[4px] flex justify-center items-center bg-[#006EAB]"
                      onClick={onClickSubmit}
                      disabled={disableSubmit}
                    >
                      {isLoading ? <Spinner /> : titleSubmit}
                    </button>
                  )}
                  {printStamp && (
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <button className="border-[1px] border-[#006EAB] py-[12px] text-[#FCFCFC] px-[20px] w-full rounded-[4px] flex justify-center items-center bg-[#006EAB]">
                            {t('print_stamp')}
                          </button>
                        )
                      }}
                      content={() => componentRef}
                      pageStyle={
                        useQRcode
                          ? ' @page { margin: -12px 18px 0px 0px  !important }'
                          : ' @page { margin: 0px 18px 0px 0px  !important }'
                      }
                    />
                  )}

                  {/* Form submittion */}
                  {navigation && (
                    <section className="w-full flex flex-row-reverse justify-evenly gap-6 pt-6">
                      <button
                        className="border-[1px] border-[#006EAB] py-[12px] text-[#FCFCFC] px-[20px] w-full rounded-[4px] flex justify-center items-center bg-[#006EAB]"
                        onClick={handleChangePage}
                      >
                        {t('next')}
                      </button>
                      <button
                        className="border-[1px] border-[#006EAB] py-[12px] text-[#073C59] px-[20px] w-full rounded-[4px] flex justify-center items-center bg-[#FCFCFC] disabled:bg-main-gray disabled:cursor-not-allowed"
                        onClick={onClickSubmit}
                        disabled={disableSubmit}
                      >
                        {isLoading ? <Spinner /> : t('save')}
                      </button>
                      <button
                        className="border-[1px] border-[#006EAB] py-[12px] text-[#FCFCFC] px-[20px] w-full rounded-[4px] flex justify-center items-center bg-[#006EAB]"
                        onClick={handleChangeBack}
                      >
                        {t('back')}
                      </button>
                    </section>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
          {displayBtns && (
            <button
              className="z-[90] absolute top-[50%] right-[10%] bg-white ml-4 rounded-full cursor-pointer disabled:cursor-not-allowed"
              disabled={disabledRight}
              onClick={onRightClick}
            >
              <AiOutlineRightCircle size={40} />
            </button>
          )}
        </div>
      </Dialog>
    </Transition>
  )
}

export default NewModal
