import { useEffect, useState } from 'react'

import TextInputRegular from './TextInputRegular'

const NumberInputRegular = ({ initialValue, handleChange, inputKey, ...props }) => {
  const [value, setValue] = useState(initialValue || '')

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <TextInputRegular
      value={value}
      onChange={(e) => {
        let input = e.target.value

        // Remove any non-digit characters from the input
        input = input.replace(/[^\d]/g, '')

        let event

        if (e.target.value === '0.0' || e.target.value === '') {
          event = {
            target: {
              value: '',
              name: 'attributeValue',
            },
          }
        } else {
          // Add leading zeros as needed
          while (input.length < 3) {
            input = '0' + input
          }

          // Add a decimal point two characters from the end
          input = input.slice(0, -2) + '.' + input.slice(-2)

          // Remove any leading zeros before the decimal point
          input = input.replace(/^0+(?=\d)/, '')
          event = {
            target: {
              value: input,
              name: 'attributeValue',
            },
          }
        }
        setValue(event.target.value)
        handleChange(event, inputKey)
      }}
      {...props}
    />
  )
}

export default NumberInputRegular
