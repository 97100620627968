import { t } from 'i18next'
import { useMemo } from 'react'

import { SingleChevronIcon } from '../../../assets/icons'

const PrefetchPagination = ({ pageSize, setPageSize, pageOptions, pageCount, setPage, setPageAmount, pageManual }) => {
  const arrayInSelect = useMemo(() => [...Array(pageOptions.length).keys()].map((i) => i + 1), [pageOptions])

  return (
    <div className="flex items-center bg-white justify-between p-2 flex-wrap w-full rounded-t-2xl -z-10">
      <button
        className="flex gap-3 items-center text-secondary capitalize pl-3"
        onClick={() => {
          setPage((prev) => prev - 1)
        }}
        disabled={pageManual === 1}
      >
        <SingleChevronIcon /> <p className="font-semibold text-sm">{t('previous')}</p>
      </button>
      <div className="rounded-md border-2 border-neutral">
        <select
          value={pageManual}
          onChange={(e) => {
            setPage(+e.target.value)
          }}
          disabled={pageCount === 1}
          className="bg-white px-3 py-2 rounded "
        >
          {arrayInSelect.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </select>
      </div>
      <button
        className="flex gap-3 items-center text-secondary capitalize pr-3"
        onClick={() => setPage((prev) => prev + 1)}
        disabled={pageManual === pageOptions.length || pageOptions.length === 0}
      >
        <p className="font-semibold text-sm">{t('next')}</p>
        <div className="rotate-180">
          <SingleChevronIcon />
        </div>
      </button>
    </div>
  )
}

export default PrefetchPagination
