import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  placeOfExpence: [],
}

const stampSlice = createSlice({
  name: 'placeOfExpence',
  initialState,
  reducers: {
    setPlaceOFExpence: (state, action) => {
      const newData = [...state.placeOfExpence, ...action.payload]
      state.placeOfExpence = [...new Set(newData)]
    },
    resetPlaceOfExpence: (state) => {
      state.placeOfExpence = []
    },
  },
})

export const { setPlaceOFExpence, resetPlaceOfExpence } = stampSlice.actions
export default stampSlice.reducer
