import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../../api/services/configuratorApi'
import NewModal from '../../../root/modals/NewModal'

const ConfirmStartNewYear = ({ open, onClose }) => {
  const { t } = useTranslation()

  const company = useSelector((state) => state.company.company)
  const [updateCompanyConfig] = useEditConfiguratorMutation()
  const { data: companyConfig } = useGetConfiguratorQuery(company?.id)

  const startNewYear = async () => {
    try {
      const keyForNewYear = new Date().getFullYear().toString().slice(-2)
      const issuedDocsKey = new Date().getFullYear().toString().slice(-2)

      const currentYear = new Date().getFullYear()

      const lastTwoDigits = currentYear % 100

      if (+companyConfig?.key === lastTwoDigits) {
        toast.error(t('current_year'))
        onClose()
        return
      }
      const { num_key, ...updatedConfig } = companyConfig

      const newConfig = { ...updatedConfig, key: keyForNewYear, issuedDocKey: issuedDocsKey }

      const response = await updateCompanyConfig({ companyId: company.id, updatedConfig: newConfig }).unwrap()
      toast.success(response.message)
      onClose()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <NewModal
      showModal={open}
      closeModal={onClose}
      title={t('start_new_year')}
      hideClose
      titleClose={t('close')}
      titleSubmit={t('confirm')}
      onClickClose={onClose}
      onClickSubmit={startNewYear}
      btns
      noChildren
    />
  )
}

export default ConfirmStartNewYear
