import { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'

import CreateUserModal from '../../components/modals/users/CreateUserModal'
import TableUsers from '../../components/table/users/TableUsers'
import useDarkMode from '../../hooks/useDarkMode'

const ModifyUsers = () => {
  useDarkMode()

  //Assign user groups
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableUsers setOpen={() => setOpen(true)} />
      <CreateUserModal onClose={() => setOpen(false)} open={open} />
    </>
  )
}

export default ModifyUsers
