import { useTranslation } from 'react-i18next'

import NewModal from '../../root/modals/NewModal'

const DeleteAttachedDocModal = ({ open, onClose, handleDelete }) => {
  const { t } = useTranslation()

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        title={t('del_label')}
        page={'middle'}
        hideClose
        btns
        titleSubmit={t('delete')}
        titleClose={t('close')}
        onClickClose={onClose}
        onClickSubmit={handleDelete}
        noChildren
      />
    </>
  )
}

export default DeleteAttachedDocModal
