import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useGetAllCompaniesQuery } from '../../../api/services/companiesApi'
import { useRegisterUserMutation } from '../../../api/services/usersApi'
import { clientStyles } from '../../../data/createStampSelectStyles'
import SelectInputRegular from '../../inputs/SelectInputRegular'
import TextInputRegular from '../../inputs/TextInputRegular'
import NewModal from '../../root/modals/NewModal'
import CustomSelectCompanyGroup from './components/CustomSelectCompanyGroup'

const CreateUserModal = ({ onClose, open }) => {
  const [user, setUser] = useState({})
  const [companies, setCompanies] = useState([])
  const [archive, setArchive] = useState()
  const [recipient, setRecipient] = useState()
  const [warning, setWarning] = useState()
  const [emailNotification, setEmailNotification] = useState()
  const [userGroups, setUserGropus] = useState([])

  const { data: allCompanies } = useGetAllCompaniesQuery({ page: 1, pageSize: 25 })

  const [registerUser] = useRegisterUserMutation()

  const { t } = useTranslation()

  const archiveOptions = [
    { value: 'All', label: 'All' },
    { value: 'Mine', label: 'Mine' },
  ]

  const recipientOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]
  const warningOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]
  const emailNotificationOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]

  const companiesOptions = allCompanies?.companies?.map((company) => ({
    value: company.id,
    label: company.name.toString() || 'No label',
  }))

  //Create user groups object
  const handleUserGroups = (e) => {
    const existingGroup = userGroups.find((el) => el.companyId === e.companyId)
    if (!existingGroup) {
      setUserGropus((prev) => [...prev, e])
    } else {
      // If a group with the same companyId exists, update its state
      setUserGropus((prev) => prev.map((group) => (group.companyId === e.companyId ? e : group)))
    }
  }

  //Creating a user
  const handleOnChange = (e) => {
    if (!e.target) {
      const value = e.value

      //If from archive select
      if (value === 'All' || value === 'Mine') {
        setUser({
          ...user,
          archive: value,
        })
      }
      //If from permission select
      else {
        setUser({
          ...user,
          groups: [e.label],
        })
      }
    }
    //If not from react-select
    else {
      const value = e.target.value
      setUser({
        ...user,
        [e.target.name]: value,
      })
    }
  }

  //Handle company select
  const selectCompany = (e) => {
    setCompanies(e)
  }

  const handleSubbmit = async (e) => {
    e.preventDefault()
    const sendCompanies = companies.map((company) => company.label)
    const sendUser = {
      ...user,
      companies: sendCompanies,
      warning: warning?.value,
      emailNotification: emailNotification?.value,
      archive: archive?.value,
      groups: userGroups,
      recipient: recipient?.value,
    }

    try {
      const response = await registerUser(sendUser).unwrap()
      setUserGropus([])
      setUser({})
      setArchive()
      setWarning()
      setEmailNotification()
      setRecipient()
      setCompanies([])
      onClose()
      toast.success(response.message)
    } catch (err) {
      toast.error(err.data.message)
    }
  }

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        hideClose
        title={t('reg_user')}
        btns
        titleClose={t('close')}
        titleSubmit={t('reg_user')}
        onClickClose={onClose}
        onClickSubmit={handleSubbmit}
      >
        {/* Form for creating a user */}

        <form className="grid grid-cols-2 gap-y-6 gap-x-4">
          <TextInputRegular
            type="email"
            value={user ? user.email : ''}
            placeholder={t('email')}
            onChange={handleOnChange}
            name="email"
          />
          <TextInputRegular
            type="text"
            value={user ? user.firstName : ''}
            placeholder={t('first_name')}
            onChange={handleOnChange}
            name="firstName"
          />
          <TextInputRegular
            type="text"
            value={user ? user.lastName : ''}
            placeholder={t('last_name')}
            onChange={handleOnChange}
            name="lastName"
          />
          <TextInputRegular
            type="text"
            value={user ? user.username : ''}
            placeholder={t('username')}
            onChange={handleOnChange}
            name="username"
          />
          <TextInputRegular
            type="text"
            value={user ? user.address : ''}
            placeholder={t('adress')}
            onChange={handleOnChange}
            name="address"
          />
          <TextInputRegular
            type="password"
            value={user ? user.password : ''}
            placeholder={t('password')}
            onChange={handleOnChange}
            name="password"
          />
          {/* Compnay select */}

          <SelectInputRegular
            options={companiesOptions}
            styles={clientStyles}
            value={companies || null}
            isMulti
            placeholder={t('company')}
            onChange={selectCompany}
          />

          {/* Archive select */}
          <div>
            <div className="">
              <Select
                options={archiveOptions}
                styles={clientStyles}
                value={archive || null}
                placeholder={t('arc')}
                name="archive"
                onChange={(e) => setArchive(e)}
              />
            </div>
          </div>

          {/* Permission select */}
          {/* Render when company is picked */}
          {/* And display company's groups */}
          {companies?.map((company, index) => (
            <>
              <CustomSelectCompanyGroup
                handleChange={handleUserGroups}
                companiesId={company.value}
                userGroups={userGroups}
              />
            </>
          ))}
          {/* Recipient select */}
          <SelectInputRegular
            options={recipientOptions}
            styles={clientStyles}
            value={recipient || null}
            placeholder={t('reciever')}
            name="archive"
            onChange={(e) => setRecipient(e)}
          />

          {/* ADD WARNING */}
          <SelectInputRegular
            styles={clientStyles}
            value={warning}
            options={warningOptions}
            placeholder="Warning"
            onChange={(e) => setWarning(e)}
          />

          {/* ADD Notification */}
          <SelectInputRegular
            styles={clientStyles}
            value={emailNotification}
            options={emailNotificationOptions}
            placeholder="Email Notification"
            onChange={(e) => setEmailNotification(e)}
          />
        </form>
      </NewModal>
    </>
  )
}

export default CreateUserModal
