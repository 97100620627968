import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../../api/services/configuratorApi'
import { DefaultStampPosition } from '../../../../components/modals/modals'

function StampPosition() {
  const [open7, setOpen7] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const company = useSelector((state) => state.company.company)
  const { data: config } = useGetConfiguratorQuery(company?.id)

  useEffect(() => {
    if (config) {
      setIsChecked(config.position === 10 ? true : false)
    }
  }, [config])

  const [updateCompanyConfig] = useEditConfiguratorMutation()

  return (
    <>
      <p className=" h-[20px] font-popins font-medium leading-[20px] text-center text-lg text-[#073C59]">
        {t('stamp_pos')}
      </p>
      <div className="flex w-[245px] h-[29px] gap-[8px]">
        <label
          className={`border border-[#D5D9EC] font-popins font-normal leading-5 text-sm cursor-pointer w-[118px] h-[29px] rounded-[8px] flex justify-center items-center gap-4 p-2 ${config?.position !== 10 ? ' text-white bg-[#006EAB]' : 'bg-white text-[#140F0F]'}`}
        >
          <input
            autoComplete="off"
            type="radio"
            id="export"
            checked={config?.position !== 10}
            className={`radio radio-xs checked:bg-white checked:radio-primary`}
            onChange={async (e) => {
              let isTrue = !isChecked
              setIsChecked((prev) => !prev)

              if (!isTrue) {
                setOpen7(!open7)
              } else {
                // Update DB config
                const newConfig = {
                  ...config,
                  position: 10,
                }

                delete newConfig?.num_key

                const response = await updateCompanyConfig({ companyId: company.id, updatedConfig: newConfig }).unwrap()
                toast.success(response.message)
              }
            }}
          />
          <p>{t('default')}</p>
        </label>
        <label
          className={`border border-[#D5D9EC] font-popins font-normal leading-5 text-sm cursor-pointer w-[118px] h-[29px] rounded-[8px] flex justify-center items-center gap-4 p-2 ${config?.position === 10 ? ' text-white bg-[#006EAB]' : 'bg-white text-[#140F0F]'}`}
        >
          <input
            autoComplete="off"
            type="radio"
            id="export"
            checked={config?.position === 10}
            className={`radio radio-xs checked:bg-white checked:radio-primary`}
            onChange={async (e) => {
              let isTrue = !isChecked
              setIsChecked((prev) => !prev)

              if (!isTrue) {
                setOpen7(!open7)
              } else {
                // Update DB config
                const newConfig = {
                  ...config,
                  position: 10,
                }

                delete newConfig?.num_key

                const response = await updateCompanyConfig({ companyId: company.id, updatedConfig: newConfig }).unwrap()
                toast.success(response.message)
              }
            }}
          />
          <p>{t('adaptive')}</p>
        </label>
      </div>

      {/* Set default stamp position */}
      <DefaultStampPosition open={open7} onClose={() => setOpen7(false)} />
    </>
  )
}

export default StampPosition
