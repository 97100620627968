import { components } from 'react-select'

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type='checkbox'
          checked={props.isSelected}
          onChange={() => null}
          className='option-checkbox'
          id='option-checkbox'
        />
        <label className='option-label'>{props.label}</label>
      </components.Option>
    </div>
  )
}

export default Option
