import { CheckIcon } from '../../assets/icons'

const FileFormSlider = ({ setPageState, pageState, totalPages }) => {
  const sliderArray = Array.from({ length: totalPages })

  return (
    <div className="w-full flex justify-between items-center h-fit p-4 rounded-badge bg-neutral/20">
      {sliderArray.map((_, index) => (
        <>
          <Circle onClick={() => setPageState(index)} number={index + 1} pageState={pageState} />
          {index === sliderArray.length - 1 ? null : <ProgressLine pageState={pageState} number={index + 2} />}
        </>
      ))}
    </div>
  )
}

export default FileFormSlider

const Circle = ({ pageState, onClick, number }) => {
  return (
    <div
      onClick={onClick}
      className={`h-10 w-10 rounded-full font-semibold flex justify-center items-center cursor-pointer ${
        pageState > number - 1
          ? 'border-[3px] border-primary bg-primary text-white'
          : pageState + 1 === number
            ? 'text-primary bg-neutral/20 border-[3px] border-primary'
            : 'bg-neutral text-black/50'
      }`}
    >
      <p className="mb-0">{pageState >= number ? <CheckIcon /> : number}</p>
    </div>
  )
}

const ProgressLine = ({ pageState, number }) => {
  return <span className={`h-1 flex-grow ${pageState >= number - 1 ? 'bg-primary' : 'bg-neutral'}`}></span>
}
