import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useGetStampOptionsQuery } from '../../../../../api/services/stampsApi'
import useFilteredOptions from '../../hooks/useFilteredOptions'
import ChooseInputModal from '../ChooseInputModal'

const AddField = ({ spredValues, nonSelectStampFields, setNonSelectStampFields, setInputFields, index }) => {
  const { data: stampOptions } = useGetStampOptionsQuery()

  const filteredOptions = useFilteredOptions(stampOptions, spredValues)

  const addInputField = () => {
    if (filteredOptions.lenght !== 0) {
      let newFields = [...nonSelectStampFields]
      newFields.splice(index + 1, 0, {
        uId: uuidv4(),
        attributeKey: '',
        attributeValue: '',
        select: false,
      })

      setNonSelectStampFields(newFields)
    } else {
      return
    }
  }

  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="w-full flex justify-start items-center">
        <button
          className="add-remove-stamp-field z-0"
          onClick={(e) => {
            e.preventDefault()

            /**
             * If there is no more default options to select from
             * Automaticly add a non-select input field
             * Since the select field will NOT have any options to choose form
             */

            setOpen(true)
          }}
        >
          +
        </button>
        {open && (
          <>
            <ChooseInputModal
              onClose={() => setOpen(false)}
              spredValues={spredValues}
              setInputFields={setInputFields}
              nonSelectStampFields={nonSelectStampFields}
              setNonSelectStampFields={setNonSelectStampFields}
              filteredOptions={filteredOptions}
              index={index}
            />
          </>
        )}
      </div>
      {open && <div className="absolute inset-0 z-20" onClick={() => setOpen(false)}></div>}
    </>
  )
}

export default AddField
