import { createSlice } from '@reduxjs/toolkit'

const user = JSON.parse(localStorage.getItem('user'))

const initialState = { user: user ? user : {} }

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload
    },
    logout: (state) => {
      state.user = {}
      localStorage.clear()
    },
  },
})

export const { setUser, resetUser, logout } = userSlice.actions
export default userSlice.reducer
