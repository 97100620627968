import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useLazyFilesDownloadQuery } from '../../api/services/filesApi'
import {
  ConfigureStampModal,
  ConfirmStartNewYear,
  SetIssuedDocStart,
  SetProtocolStartModal,
} from '../../components/modals/modals'
import Card from '../../components/other/Card'
import { newYSvg, svg } from '../../data/configuratorData'
import CommentBeforeApprove from './configurator/components/CommentBeforeApprove'
import CommentBeforeRejection from './configurator/components/CommentBeforeRejection'
import QRCode from './configurator/components/QRCode'
import StampPosition from './configurator/components/StampPosition'
import StampPrint from './configurator/components/StampPrint'
import UsersBeforeApproval from './configurator/components/UsersBeforeApproval'

const ConfiguratorMenu = () => {
  const { t } = useTranslation()

  const company = useSelector((state) => state.company.company)

  const [download] = useLazyFilesDownloadQuery()

  const [start, setStart] = useState('')
  const [issuedDocStart, setIssuedStart] = useState('')
  const [open2, setOpen2] = useState(false)
  const [open10, setOpen10] = useState(false)
  const [open12, setOpen12] = useState(false)
  const [open15, setOpen15] = useState(false)

  const navigate = useNavigate()

  const components = [
    { id: 1, component: <QRCode /> },
    { id: 2, component: <CommentBeforeApprove /> },
    { id: 3, component: <CommentBeforeRejection /> },
    { id: 4, component: <StampPosition /> },
    { id: 5, component: <StampPrint /> },
    { id: 6, component: <UsersBeforeApproval /> },
  ]

  const cards = [
    {
      id: 1,
      label: t('logs'),
      onClick: () => navigate('logs'),
      svgKey: 'svgLogs',
    },
    {
      id: 2,
      label: t('config_stamp'),
      onClick: () => setOpen2(true),
      svgKey: 'configureStampLogs',
    },
    {
      id: 3,
      label: t('edit_doc'),
      onClick: () => navigate('edit-document'),
      svgKey: 'editDocumentSvg',
    },
    {
      id: 4,
      label: t('associate_files'),
      onClick: () => navigate('associate-files'),
      svgKey: 'associateFilesSvg',
    },
    {
      id: 5,
      label: t('del_doc'),
      onClick: () => navigate('delete-document'),
      svgKey: 'deleteDocument',
    },
    {
      id: 6,
      label: t('rest_doc'),
      onClick: () => navigate('restore-document'),
      svgKey: 'restoreDocumentSvg',
    },
    {
      id: 7,
      label: t('company_groups'),
      onClick: () => navigate('company-groups'),
      svgKey: 'companyGroupsSvg',
    },
    {
      id: 8,
      label: t('company_categories'),
      onClick: () => navigate('company-categories'),
      svgKey: 'companyCategoriesSvg',
    },
  ]

  return (
    <>
      <div className="w-full h-full flex justify-center items-center gap-[20px] mt-6">
        <div className="w-full h-full gap-[20px] flex flex-col">
          <div
            className="w-full h-[48px] rounded-[4px] py-[12px] px-[20px] bg-[#006EAB] flex justify-center items-center gap-[20px] cursor-pointer"
            onClick={() => setOpen10(true)}
          >
            <span>{newYSvg}</span>
            <p className="w-full h-[24px] font-popins text-base leading-[24px] font-semibold text-center text-[#FCFCFC]">
              {t('start_year')}
            </p>
          </div>

          <div className="w-full h-full rounded-lg border-[1px] py-[20px] px-[40px] gap-[40px] bg-[#F7F7F7] border-[#D5D9EC]">
            <div className="w-full h-full flex flex-col gap-[20px]">
              {components?.map((item, index) => (
                <div
                  key={item.id}
                  className={`h-[80px] ${index !== components.length - 1 ? 'border-b-[1px]' : ''} flex items-center justify-between pb-[8px]`}
                >
                  {item.component}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full h-[48px] gap-[20px]">
            <div className="w-full h-[48px] flex gap-[16px]">
              <div
                className="bg-[#006EAB] cursor-pointer w-full h-[48px] rounded-[4px] py-[12px] px-[20px] flex justify-center items-center gap-[20px]"
                onClick={() => setOpen12(true)}
              >
                <p className="text-[#FCFCFC] h-[24px] font-popins font-semibold text-base leading-[24px] flex items-center justify-center">
                  {t('set_st_pr_num')}
                </p>
              </div>
              <div
                className="bg-[#006EAB] cursor-pointer w-full h-[48px] rounded-[4px] py-[12px] px-[20px] flex  justify-center items-center gap-[20px]"
                onClick={() => setOpen15(true)}
              >
                <p className="text-[#FCFCFC] h-[24px] font-popins font-semibold text-base leading-[24px] flex items-center justify-center">
                  {t('set_st_iss_num')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-full rounded-[8px] border-[1px] p-[20px] flex flex-col gap-[12px] bg-[#F7F7F7] border-[#D5D9EC]">
          {cards.map((card, index) => (
            <Card
              key={card.id}
              label={card.label}
              onClick={card.onClick}
              svg={svg[card.svgKey]}
              index={index}
              length={cards?.length}
            />
          ))}
        </div>
      </div>

      <ConfirmStartNewYear open={open10} onClose={() => setOpen10(false)} />
      <SetProtocolStartModal onClose={() => setOpen12(false)} setStart={setStart} start={start} open={open12} />
      {/* Set start for issued doc */}
      <SetIssuedDocStart
        open={open15}
        onClose={() => setOpen15(false)}
        setStart={setIssuedStart}
        start={issuedDocStart}
      />
      <ConfigureStampModal onClose={() => setOpen2(false)} open={open2} />
    </>
  )
}

export default ConfiguratorMenu
