import { api } from '../api'

export const statusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStatuses: builder.query({
      query: () => ({
        url: `/protocol/statuses`,
      }),
      providesTags: ['Status'],
    }),
  }),
})

export const { useGetStatusesQuery } = statusApi
