import { t } from 'i18next'
import { useMemo } from 'react'

import { DoubleChevronIcon, SingleChevronIcon } from '../../../assets/icons'

const PrefetchPagination = ({ pageSize, setPageSize, pageOptions, pageCount, setPage, setPageAmount, pageManual }) => {
  const arrayInSelect = useMemo(() => [...Array(pageOptions.length).keys()].map((i) => i + 1), [pageOptions])

  return (
    <div className="flex items-center bg-white justify-between p-2 flex-wrap w-full rounded-t-2xl -z-10">
      <button
        className="bg-white disabled:cursor-not-allowed"
        onClick={() => {
          setPage(1)
        }}
        disabled={pageManual === 1}
      >
        <DoubleChevronIcon />
      </button>
      <button
        className="flex gap-4 items-center text-secondary capitalize"
        onClick={() => {
          setPage((prev) => prev - 1)
        }}
        disabled={pageManual === 1}
      >
        <SingleChevronIcon /> <p>{t('previous')}</p>
      </button>
      <div className="rounded-md border-2 border-neutral">
        <select
          value={pageManual}
          onChange={(e) => {
            setPage(+e.target.value)
          }}
          disabled={pageCount === 1}
          className="bg-white px-6 py-2 rounded "
        >
          {arrayInSelect.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </select>
      </div>
      <div className="text-secondary font-semibold text-sm">
        {pageManual}/{pageOptions.length}
      </div>
      <div className="rounded-md border-2 border-neutral">
        <select
          className="bg-white p-2 rounded"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
            setPageAmount(Number(e.target.value))
            setPage(1)
          }}
        >
          {[10, 25, 50, 100, 250, 500, 1000].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Prikaži {pageSize}
            </option>
          ))}
        </select>
      </div>
      <button
        className="flex gap-4 items-center text-secondary capitalize"
        onClick={() => setPage((prev) => prev + 1)}
        disabled={pageManual === pageOptions.length || pageOptions.length === 0}
      >
        <p>{t('next')}</p>
        <div className="rotate-180">
          <SingleChevronIcon />
        </div>
      </button>
      <button
        className="bg-white disabled:cursor-not-allowed"
        onClick={() => {
          setPage(pageCount)
        }}
        disabled={pageManual === pageOptions.length || pageOptions.length === 0}
      >
        <div className="rotate-180">
          <DoubleChevronIcon />
        </div>
      </button>
    </div>
  )
}

export default PrefetchPagination
