import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { FcViewDetails } from 'react-icons/fc'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAsyncDebounce } from 'react-table'

import { useGetCategoryQuery } from '../../../api/services/categoriesApi'
import { useGetCustomStampsQuery } from '../../../api/services/stampsApi'
import { ProcessIcon } from '../../../assets/icons'
import viewAttachments from '../../../assets/images/view-attachment.png'
import ViewProcessImg from '../../../assets/images/view-process.png'
import viewRelation from '../../../assets/images/view-relation.png'
import { FileProcessModal, ViewModal } from '../../modals/modals'
import ModalDataMobile from '../../root/modals/ModalDataMobile'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import SelectColumnFilterPaginated from '../components/SelectColumnFilterPaginated'
import ProtocolBookUniqueTable from '../ProtocolBookUniqueTable'

const TableProtocolBook = () => {
  const [open, setIsOpen] = useState(false)
  const [openProcess, setOpenProcess] = useState(false)
  const [comment, setComment] = useState('')
  const [primaryDocumentId, setPrimaryDocumentId] = useState('')
  const [fileId, setFileId] = useState('')
  const [fileAddress, setFileAddress] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})
  const navigate = useNavigate()

  const [openDataTableModal, setOpenDataTableModal] = useState(false)

  const [row, setRow] = useState()

  const [viewArray, setViewArray] = useState()

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const company = useSelector((state) => state.company.company)

  const { data: stampFields } = useGetCustomStampsQuery({
    companyId: company?.id,
  })

  const { data: category } = useGetCategoryQuery(company?.id)

  let subjectHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Predmet' || el.attributeKey === 'Broj fakture') {
      return el
    }
  })
  let clientHeader = stampFields?.find((el) => {
    if (
      el.attributeKey === 'Klijent' ||
      el.attributeKey === 'Partner' ||
      el.attributeKey === 'Dobavljač' ||
      el.attributeKey === 'Kupac'
    ) {
      return el
    }
  })
  let locationHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Mjesto' || el.attributeKey === 'Sjedište') {
      return el
    }
  })
  let dateHeader1 = stampFields?.find((el) => {
    if (el.attributeKey === 'Datum prijema') {
      return el
    }
  })
  let dateHeader = stampFields?.find((el) => {
    if (el.attributeKey === 'Datum dokumenta' || el.attributeKey === 'Datum predmeta') {
      return el
    }
  })

  //Visible fields from company's stamp fields
  const visibleFields = stampFields
    ?.filter((stampKey) => {
      if (stampKey.isVisible && stampKey.attributeKey !== 'Stamp ID') {
        return stampKey
      }
    })
    .map((key) => {
      //For certain fields defaulting to same accessor
      //This is due to the way DB is setup and changed over time
      switch (key.attributeKey) {
        case 'Predmet':
          return {
            Header: t('subject'),
            accessor: 'Predmet',
          }
        case 'Broj fakture':
          return {
            Header: subjectHeader?.attributeKey || t('subject'),
            accessor: 'Predmet',
          }
        case 'Klijent':
          return {
            Header: t('client'),
            accessor: 'Klijent',
          }
        case 'Dobavljač':
          return {
            Header: clientHeader?.attributeKey || t('client'),
            accessor: 'Klijent',
          }
        case 'Kupac':
          return {
            Header: clientHeader?.attributeKey || t('client'),
            accessor: 'Klijent',
          }
        case 'Partner':
          return {
            Header: clientHeader?.attributeKey || t('client'),
            accessor: 'Klijent',
          }
        case 'Lokacija':
          return {
            Header: locationHeader?.attributeKey || t('location'),
            accessor: 'Lokacija',
          }
        case 'Mjesto':
          return {
            Header: t('location'),
            accessor: 'Lokacija',
          }
        case 'Sjedište':
          return {
            Header: locationHeader?.attributeKey || t('location'),
            accessor: 'Lokacija',
          }
        case 'Datum protokola':
          return {
            Header: t('date_of_prijem'),
            accessor: 'Datum prijema',
            Filter: ({ column }) => {
              return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
            },
          }
        case 'Datum predmeta':
          return {
            Header: t('date_of_document'),
            accessor: 'Datum predmeta',
            Filter: ({ column }) => {
              return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
            },
          }
        case 'Datum dokumenta':
          return {
            Header: t('date_of_document'),
            accessor: 'Datum predmeta',
            Filter: ({ column }) => {
              return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
            },
          }
        default:
          return {
            Header: key.attributeKey,
            accessor: key.attributeKey,
          }
      }
    })

  const handleClick = (row) => {
    setFileId(row?.original?.id)
    setFileAddress(row?.original?.address)
    setComment(row.original?.comment)
    setIsOpen(true)
  }
  const newDynamicColumns = useMemo(
    () => [
      {
        Header: t('stamp_key'),
        accessor: 'Sifra',
        Cell: ({ row }) => {
          return (
            <button
              className="cursor-pointer text-sky-700 hover:underline hover:text-sky-700/70"
              onClick={() => handleClick(row)}
            >
              {row.original.Sifra}
            </button>
          )
        },
      },
      ...(visibleFields || []),

      {
        Header: 'Status',
        accessor: 'Status',
        Cell: ({ row }) => {
          let status =
            row.original.Status === 'Approved'
              ? t('approved')
              : row.original.Status === 'Rejected'
                ? t('rejected')
                : t('awaiting')

          const color =
            row.original.Status === 'Approved'
              ? 'bg-main-green'
              : row.original.Status === 'Rejected'
                ? 'bg-red-600'
                : 'bg-primary'

          return <div className={`${color} text-white rounded-lg font-thin`}>{status}</div>
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              advancedOptions
              handleFilterChange={handleFilterChange}
              providedOptions={[
                { label: t('approved'), value: 1 },
                { label: t('rejected'), value: 2 },
                { label: t('awaiting'), value: 3 },
              ]}
            />
          )
        },
      },
      {
        Header: t('type'),
        accessor: 'Tip',
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              advancedOptions
              handleFilterChange={handleFilterChange}
              providedOptions={[
                { label: 'Računovodstveni', value: 1 },
                { label: 'Pravni', value: 2 },
              ]}
            />
          )
        },
      },
      {
        Header: t('category'),
        accessor: 'Kategorije',
        Cell: ({ row }) => {
          let category = row.original.Kategorije
          if (!category) return 'Nema kategorije'
          return category
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              advancedOptions
              handleFilterChange={handleFilterChange}
              providedOptions={category?.map((item) => {
                return {
                  label: item?.categoryName,
                  value: item?.id,
                }
              })}
            />
          )
        },
      },
      {
        Header: t('comment'),
        accessor: 'comments',
        Cell: ({ row }) => {
          const commentString = row.original?.comments
            ? row.original?.comments?.reduce((prev, curr) => prev + `${curr.comment}; `, '')
            : ''
          return commentString
        },
      },
      {
        Header: t('kind'),
        accessor: 'advance',
        Cell: ({ row }) => {
          let advance = row.original.advance === 1 ? t('advance') : ''
          let clasified = row.original.clasified === 1 ? t('clasified') : ''
          let importt = row.original?.importOption === 1 ? t('import') : ''
          let exportt = row.original?.exportOption === 1 ? t('export') : ''
          let reExport = row.original.reExport === 1 ? 're-export' : ''
          let recieved = row.original.received === 1 ? t('recieved') : ''
          let issued = row.original.issued === 1 ? t('clasified') : ''
          let KO = row.original.KO === 1 ? t('K.O_document') : ''
          return (
            <div className="flex flex-col w-full justify-center items-center">
              <p className="mb-0">{advance}</p>
              <p className="mb-0">{importt}</p>
              <p className="mb-0">{exportt}</p>
              <p className="mb-0">{clasified}</p>
              <p className="mb-0">{reExport}</p>
              <p className="mb-0">{recieved}</p>
              <p className="mb-0">{issued}</p>
              <p className="mb-0">{KO}</p>
            </div>
          )
        },
        Filter: ({ column }) => {
          return (
            <SelectColumnFilterPaginated
              column={column}
              advancedOptions
              handleFilterChange={handleFilterChange}
              // providedOptions={[
              //   { label: 'Advance', value: 1 },
              //   { label: 'Import', value: 2 },
              //   { label: 'Export', value: 3 },
              //   { label: 'Re-export', value: 4 },
              //   { label: 'Recieved', value: 5 },
              //   { label: 'Issued', value: 6 },
              //   { label: 'KO', value: 7 },
              // ]}
              providedOptions={[
                { label: t('advance'), value: 1 },
                { label: t('import'), value: 2 },
                { label: t('export'), value: 3 },
                { label: 'Re-export', value: 4 },
                { label: t('recieved'), value: 5 },
                { label: t('clasified'), value: 6 },
                { label: t('K.O_document'), value: 7 },
              ]}
            />
          )
        },
      },
    ],
    [visibleFields, category],
  )
  const columns = useMemo(() => newDynamicColumns, [stampFields, category])

  const columnsMobile = useMemo(
    () => newDynamicColumns?.filter((item) => item?.accessor === 'Klijent' || item?.accessor === 'Sifra'),
    [newDynamicColumns],
  )

  const tableHooksArray = [
    {
      label: t('view'),
      Icon: AiFillEye,
      onClick: (row) => {
        setFileId(row.original.id)
        setFileAddress(row.original.address)
        setIsOpen(true)
        setComment(row.original.comment)
      },
      disabled: false,
    },
    {
      label: t('process'),
      Icon: ProcessIcon,
      onClick: (row) => {
        setFileId(row.original.id)
        setOpenProcess(true)
      },
      disabled: false,
    },
    {
      label: t('relations'),
      Image: viewRelation,
      onClick: (row) => {
        setPrimaryDocumentId(row.original.Sifra)
        navigate(`relations/${row?.original?.id}`)
      },
      disabled: false,
    },
    {
      label: t('attachments'),
      Image: viewAttachments,
      onClick: (row) => {
        setPrimaryDocumentId(row.original.Sifra)
        navigate(`attachments/${row.original.id}`)
      },
      disabled: false,
    },
  ]
  const tableHooksArrayMobile = [
    {
      label: t('view'),
      Icon: AiFillEye,
      onClick: (row) => {
        setFileId(row.original.id)
        setFileAddress(row.original.address)
        setIsOpen(true)
        setComment(row.original.comment)
      },
      disabled: false,
    },
    {
      label: t('process'),
      Icon: ProcessIcon,
      onClick: (row) => {
        setFileId(row.original.id)
        setOpenProcess(true)
      },
      disabled: false,
    },
    {
      label: t('relations'),
      Image: viewRelation,
      onClick: (row) => {
        setPrimaryDocumentId(row.original.Sifra)
        navigate(`relations/${row?.original?.id}`)
      },
      disabled: false,
    },
    {
      label: t('attachments'),
      Image: viewAttachments,
      onClick: (row) => {
        setPrimaryDocumentId(row.original.Sifra)
        navigate(`attachments/${row.original.id}`)
      },
      disabled: false,
    },
    {
      label: 'Details',
      Icon: FcViewDetails,
      onClick: (row) => {
        setOpenDataTableModal(true)
        setRow(row)
      },
      disabled: false,
    },
  ]

  return (
    <>
      {/* za mobile */}
      <div className="lg:hidden">
        <ProtocolBookUniqueTable
          columns={columnsMobile}
          tableHooks={tableHooksArrayMobile}
          filterInputs={filterInputs}
          page={page}
          pageSize={pageSize}
          setFilterInputs={setFilterInputs}
          setPage={setPage}
          setPageSize={setPageSize}
          setViewArray={setViewArray}
        />
      </div>
      {/* za desktop */}
      <div className="hidden lg:block">
        <ProtocolBookUniqueTable
          columns={columns}
          tableHooks={tableHooksArray}
          filterInputs={filterInputs}
          page={page}
          pageSize={pageSize}
          setFilterInputs={setFilterInputs}
          setPage={setPage}
          setPageSize={setPageSize}
          setViewArray={setViewArray}
        />
      </div>

      <ViewModal
        id={fileId}
        address={fileAddress}
        open={open}
        onClose={() => setIsOpen(false)}
        comment={comment}
        displayComment={true}
        viewArray={viewArray}
        displayBtns={true}
        btns={false}
        page="protocol"
      />
      <FileProcessModal
        open={openProcess}
        fileId={fileId}
        onClose={() => {
          // resetFileStatusData();
          setOpenProcess(false)
        }}
      />
      <ModalDataMobile
        openDataTableModal={openDataTableModal}
        row={row}
        setOpenDataTableModal={setOpenDataTableModal}
        columns={columns}
      />
    </>
  )
}
export default TableProtocolBook
