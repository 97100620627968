import { api } from '../api'

export const groupsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllGroups: builder.query({
      query: () => ({
        url: `/groups/get-all-groups`,
      }),
      providesTags: ['Groups'],
    }),
    getMultipleCompanyGroups: builder.mutation({
      query: (companies) => ({
        url: '/groups/get-multiple-company-groups',
        body: companies,
        method: 'POST',
      }),
      invalidatesTags: ['Groups'],
    }),
    getCompanyGroups: builder.query({
      query: (companyId) => ({
        url: `/groups/get-company-groups/${companyId}`,
      }),
      providesTags: ['Groups'],
    }),
    deleteGroup: builder.mutation({
      query: (groupId) => ({
        url: `/groups/delete-group/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),
    createGroups: builder.mutation({
      query: ({ companyId, group }) => ({
        url: `/groups/create-group/${companyId}`,
        method: 'POST',
        body: group,
      }),
      invalidatesTags: ['Groups'],
    }),
    getCompanyGroupsByCompanyId: builder.query({
      query: (companyId) => ({
        url: `/groups/get-company-groups/${companyId}`,
      }),
      invalidatesTags: ['Groups'],
    }),
  }),
})

export const {
  useGetAllGroupsQuery,
  useGetMultipleCompanyGroupsMutation,
  useGetCompanyGroupsQuery,
  useDeleteGroupMutation,
  useCreateGroupsMutation,
} = groupsApi
