import React from 'react'

const DocumentTypeRadioButton = ({ onChange, checked, title }) => {
  return (
    <label
      className={`capitalize w-full cursor-pointer tracking-tight flex gap-4 p-2 ${checked ? 'font-semibold text-white bg-secondary rounded-t-xl' : 'text-black font-thin'}`}
    >
      <input
        autoComplete="off"
        type="checkbox"
        id="export"
        checked={checked}
        className={`radio ${!checked ? 'radio-accent' : 'radio-primary'} border-2`}
        onChange={onChange}
      />
      <p>{title}</p>
    </label>
  )
}

export default DocumentTypeRadioButton
