import React from 'react'

const Loading = () => {
  return (
    <div className='loading'>
      <p className='m-0 text-xl'>Loading...</p>
    </div>
  )
}

export default Loading
