import { api } from '../api'

export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    editCompany: builder.mutation({
      query: ({ companyData, companyId }) => ({
        url: `/companies/edit-company/${companyId}`,
        method: 'PUT',
        body: companyData,
      }),
      invalidatesTags: ['Companies'],
    }),
    deleteCompany: builder.mutation({
      query: ({ companyId }) => ({
        url: `/companies/delete-company/${companyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Companies'],
    }),
    userCompanies: builder.query({
      query: (userID) => ({
        url: `/companies/user-companies/${userID}`,
      }),
      providesTags: ['Companies'],
    }),
    getAllCompanies: builder.query({
      query: ({ page, pageSize, query }) => ({
        url: `/companies/get-all-companies/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Companies'],
    }),
    getAllCompaniesNoPagination: builder.query({
      query: () => ({
        url: `/companies/get-all-companies`,
      }),
      providesTags: ['Companies'],
    }),
    getUniqueCompanies: builder.query({
      query: () => ({
        url: `/companies/get-unique-companies`,
      }),
      providesTags: ['Companies'],
    }),
    createCompanies: builder.mutation({
      query: (body) => ({
        url: `/companies/create-company`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Companies'],
    }),
    uploadCompanyLogo: builder.mutation({
      query: ({ fileType, formData }) => ({
        url: `/companies/upload-company-logo?contentType=${fileType}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Companies'],
    }),
    generateLogoUrl: builder.mutation({
      query: ({ fileName }) => ({
        url: `/companies/generate-logo-url`,
        method: 'PUT',
        body: { fileName, kind: 'protocolstorage' },
      }),
      invalidatesTags: ['Companies'],
    }),
    updateCompanyLogoField: builder.mutation({
      query: ({ companyId, url }) => ({
        url: `/companies/update-company-logo-field/?id=${companyId}`,
        method: 'PUT',
        body: { url },
      }),
      invalidatesTags: ['Companies'],
    }),
    getAllDeleteCompanies: builder.query({
      query: ({ page, pageSize, query }) => ({
        url: `/companies/get-all-deleted-companies/${page}/${pageSize}${query ? `/?${query}` : ''}`,
      }),
      providesTags: ['Companies'],
    }),
    restoreCompany: builder.mutation({
      query: (companyId) => ({
        url: `/companies/restore-company/${companyId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Companies'],
    }),
  }),
})

export const {
  useEditCompanyMutation,
  useDeleteCompanyMutation,
  useUserCompaniesQuery,
  useLazyUserCompaniesQuery,
  useGetAllCompaniesQuery,
  useGetAllCompaniesNoPaginationQuery,
  useGetUniqueCompaniesQuery,
  useCreateCompaniesMutation,
  useUploadCompanyLogoMutation,
  useGenerateLogoUrlMutation,
  useUpdateCompanyLogoFieldMutation,
  useGetAllDeleteCompaniesQuery,
  useRestoreCompanyMutation,
} = companiesApi
