import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define an API slice--
export const api = createApi({
  tagTypes: [
    'Stamps',
    'Logs',
    'Categories',
    'Files',
    'Documents',
    'Protocol',
    'PlaceOfExpanse',
    'Companies',
    'Attachments',
    'DocumentRelations',
    'Companies',
    'Alerts',
    'Email',
    'Groups',
    'Configurator',
    'Users',
    'Logs',
    'Function',
    'AwaitingDocs',
    'Status',
  ],
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_IP}/` }), // Set your API base URL here
  endpoints: (builder) => ({}),
})
