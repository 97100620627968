import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useGetConfiguratorQuery } from '../../../api/services/configuratorApi'
import { useFileAproveMutation, useFileRejectMutation } from '../../../api/services/filesApi'
import { useGetProtocolCommentsQuery } from '../../../api/services/protocolApi'
import { useApproveFileMutation } from '../../../api/services/usersApi'
import NewModal from '../../root/modals/NewModal'
import FileDisplay from './FileViewer'

const ProtocolModal = ({ isOpen, id, onClose, setIsDone, isDone, address }) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) setIsLoading(false)
  }, [isOpen])

  const company = useSelector((state) => state.company.company)
  const user = useSelector((state) => state.user.user)
  const [searchParams] = useSearchParams()

  const { t } = useTranslation()
  const { data: config } = useGetConfiguratorQuery(searchParams.get('id') ? searchParams.get('id') : company.id, {
    skip: !searchParams.get('id') && !company.id,
  })

  const { data: comments } = useGetProtocolCommentsQuery(
    {
      fileId: id,
    },
    { skip: !id },
  )

  const usersToApprove = config?.usersNeeded
  const requireApprove = config?.commentBeforeApprove
  const requireReject = config?.commentBeforeReject

  // Approve file
  const [userApproval] = useApproveFileMutation()
  // Approve file for a single user
  const [fileApprove] = useFileAproveMutation()
  // Reject file for a single user
  const [fileReject] = useFileRejectMutation()

  const handleApprove = async (id) => {
    try {
      if (requireApprove && !isDone) {
        toast.error(t('add_comment'))
        return
      }

      if (usersToApprove === 'one') {
        await approveFileSingleUser(id, isDone, user?.id)
      } else {
        await approveFile(id, isDone, user?.id)
      }

      setIsDone('')
      toast.success(t('doc_approved'))
      onClose()
    } catch (error) {
      toast.error(t('error_occurred'))
      console.error(error)
    }
  }

  const handleReject = async (id) => {
    try {
      if (requireReject && !isDone) {
        toast.error(t('add_comment'))
        return
      }

      if (usersToApprove === 'one') {
        await rejectFileSingleUser(id, isDone, user?.id)
      } else {
        await rejectFileSingleUser(id, isDone, user?.id)
      }

      setIsDone('')
      toast.success(t('doc_rejected'))
      onClose()
    } catch (error) {
      toast.error(t('error_occurred'))
      console.error(error)
    }
  }

  // Approve file
  const approveFile = async (fileId, comment, userId) => {
    setIsLoading(true)

    try {
      await userApproval({ userId, fileId, comment }).unwrap()
    } catch (error) {
      toast.error(t('error_occurred'))
    }
  }

  const approveFileSingleUser = async (fileId, comment, userId) => {
    setIsLoading(true)

    try {
      await fileApprove({ fileId, userId, comment }).unwrap()
    } catch (error) {
      toast.error(t('error_occurred'))
    }
  }

  const rejectFileSingleUser = async (fileId, comment, userId) => {
    setIsLoading(true)

    try {
      await fileReject({ fileId, userId, comment }).unwrap()
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <NewModal showModal={isOpen} setShowModal={onClose} page="Table" title={`${t('approve')} / ${t('reject')}`}>
      <div className="flex flex-col lg:flex-row lg:gap-4">
        <FileDisplay address={address} />
        <div className="flex flex-col justify-between">
          <textarea
            id="comment"
            type="text"
            placeholder={t('comment')}
            onChange={(e) => setIsDone(e.target.value)}
            rows={3}
            required
            className="border-2 border-[#0D5C8D] mt-1 h-[400px] rounded-xl outline-none w-full"
          />
          <div className="">
            <span className="flex flex-col lg:flex-row justify-evenly gap-6 lg:gap-10 mt-4 lg:mt:0">
              <button
                className="text-white text-lg font-semibold bg-[#0D5C8D] disabled:bg-[#0D5C8D]/50 h-[64px] px-10 rounded-xl drop-shadow-2xl shadow-md"
                onClick={() => handleApprove(id)}
                disabled={isLoading}
              >
                {t('approve')}
              </button>
              <button
                className="text-[#0D5C8D] text-lg font-semibold border border-black bg-white disabled:bg-gray-300 h-[64px] px-10 rounded-xl drop-shadow-2xl shadow-md"
                onClick={() => handleReject(id)}
                disabled={isLoading}
              >
                {t('reject')}
              </button>
            </span>
          </div>
        </div>
      </div>
      {comments?.length > 0 && (
        <div>
          {comments?.map((item) => {
            return (
              <div className="mt-2 border-2 border-[#0D5C8D] rounded-xl p-2">
                <p className="font-bold text-[#0D5C8D] flex justify-start">Username: {item.username}</p>
                <p className="h-full w-full flex text-start">{item.comment}</p>
              </div>
            )
          })}
        </div>
      )}
    </NewModal>
  )
}

export default ProtocolModal
