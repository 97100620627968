import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  useCreateCompaniesMutation,
  useGenerateLogoUrlMutation,
  useUpdateCompanyLogoFieldMutation,
  useUploadCompanyLogoMutation,
} from '../../../../api/services/companiesApi'
import { useGetAllFunctionsQuery } from '../../../../api/services/functionsApi'
import { useCreateCustomStampsMutation } from '../../../../api/services/stampsApi'
import { clientStyles } from '../../../../data/createStampSelectStyles'
import SelectInputRegular from '../../../inputs/SelectInputRegular'
import TextInputRegular from '../../../inputs/TextInputRegular'
import NewModal from '../../../root/modals/NewModal'

const CreateCompanyModal = ({ open, onClose }) => {
  const { t } = useTranslation()

  const [company, setCompany] = useState({})
  const [tax, setTax] = useState()
  const [img, setImg] = useState()
  const [fileName, setFileName] = useState('')
  const [fileType, setFileType] = useState('')

  const { data: allFunctionos } = useGetAllFunctionsQuery()
  const [createCompanies] = useCreateCompaniesMutation()
  const [uploadCompanyLogo] = useUploadCompanyLogoMutation()
  const [generateLogoUrl] = useGenerateLogoUrlMutation()
  const [updateCompanyLogoField] = useUpdateCompanyLogoFieldMutation()
  const [createCustomStamps] = useCreateCustomStampsMutation()

  const registerCompay = async (company, logoImg, fileName, fileType) => {
    try {
      const response = await createCompanies(company).unwrap()

      const companyId = response?.companyId

      const constInputFields = [
        { attributeKey: 'Stamp ID', attributeValue: '', isVisible: true },
        { attributeKey: 'Predmet', attributeValue: '', isVisible: true },
        { attributeKey: 'Klijent', attributeValue: '', isVisible: true },
        { attributeKey: 'Mjesto', attributeValue: '', isVisible: true },
        {
          attributeKey: 'Datum protokola',
          attributeValue: '',
          isVisible: true,
        },
        { attributeKey: 'Datum predmeta', attributeValue: '', isVisible: true },
        { attributeKey: 'Valuta', attributeValue: '', isVisible: true },
        {
          attributeKey: 'Vrijednost bez PDV',
          attributeValue: '',
          isVisible: true,
        },
        { attributeKey: 'PDV', attributeValue: '', isVisible: true },
      ]

      const formData = new FormData()
      formData.append('file', logoImg)

      if (logoImg) {
        await uploadCompanyLogo({ fileType, formData }).unwrap()

        const url = await generateLogoUrl({ fileName }).unwrap()

        await updateCompanyLogoField({ companyId, url }).unwrap()
      }

      await createCustomStamps({ companyId, fields: constInputFields }).unwrap()

      toast.success(response.message)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  let functionOptions = []

  allFunctionos?.forEach((fun, index) => {
    if (fun.name !== 'admin-dashboard') functionOptions.push({ value: index, label: fun.name })
  })

  const taxOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]

  // //Handle image select
  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setFileName(file.name)
    setFileType(file.type)
    setImg(file)
  }

  //Create company
  const handleOnChange = (e) => {
    if (!e.target) {
      setCompany({
        ...company,
        tax: e.value,
      })
    } else {
      if (e.target.name === 'tax') {
        setCompany({
          ...company,
          [e.target.name]: e.target.value === 'true' ? true : false,
        })
      } else {
        const value = e.target.value
        setCompany({
          ...company,
          [e.target.name]: value,
        })
      }
    }
  }

  //Subbmit company
  const handleSubbmit = (e) => {
    e.preventDefault()

    if (!company.name || !company.address || !company.phone) return toast.error(t('input_all_fields'))

    registerCompay(
      {
        ...company,
        // groups: selectedGrups,
        tax: tax?.value,
        pin: company?.pin || '',
        taxId: company?.taxId || '',
        ip: company?.ip || '',
      },
      img,
      fileName,
      fileType,
      setCompany({}),
    )
    onClose(false)
  }

  return (
    <>
      <NewModal
        setShowModal={onClose}
        showModal={open}
        title
        page={'middle'}
        btns
        titleClose={t('save')}
        titleSubmit={t('reg_company')}
        onClickSubmit={handleSubbmit}
      >
        {/* <div className="overlay" onClick={onClose} /> */}
        <div className="flex justify-evenly">
          {/* Form for creating a company */}
          <form className="flex flex-col gap-y-6 w-full" onSubmit={handleSubbmit}>
            <h1 className="text-[#0D5C8D] text-xl">{t('reg_company')}</h1>
            <div className="flex flex-col gap-4 mt-0.5 w-full">
              <TextInputRegular
                name="name"
                value={company.name || ''}
                placeholder={t('name')}
                onChange={handleOnChange}
              />

              <TextInputRegular
                name="address"
                value={company.address || ''}
                placeholder={t('adress')}
                onChange={handleOnChange}
              />

              <TextInputRegular
                name="phone"
                value={company.phone || ''}
                placeholder={t('phone')}
                onChange={handleOnChange}
              />

              <div className="flex flex-col gap-2">
                {/* Tax select */}
                <SelectInputRegular
                  options={taxOptions}
                  styles={clientStyles}
                  value={tax || ''}
                  placeholder="Tax..."
                  name="tax"
                  onChange={(e) => setTax(e)}
                />

                {tax?.value && (
                  <div className="flex flex-col w-full items-center">
                    <TextInputRegular
                      name="taxId"
                      onChange={handleOnChange}
                      value={company?.taxId || ''}
                      placeholder="Tax ID"
                    />
                  </div>
                )}
                {tax?.value && (
                  <div className="flex flex-col w-full items-center">
                    <TextInputRegular
                      type="password"
                      name="pin"
                      onChange={handleOnChange}
                      value={company?.pin || ''}
                      placeholder="ERP PIN"
                    />
                  </div>
                )}
                {tax?.value && (
                  <div className="flex flex-col w-full items-center">
                    <TextInputRegular name="ip" onChange={handleOnChange} value={company?.ip || ''} placeholder="IP" />
                  </div>
                )}
                <div className="flex flex-col w-full h-full items-center justify-center border-[1px] border-[#D5D9EC] py-4 px-4 rounded">
                  <div className="flex items-center justify-center p-[6px] gap-4">
                    <label htmlFor="logo" className="cursor-pointer flex items-center">
                      <input
                        id="logo"
                        type="file"
                        placeholder="Image..."
                        accept="image/jpg"
                        onChange={handleImageChange}
                        className="hidden"
                      />
                      <span className="flex items-center">
                        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.75" width="48" height="48" rx="24" fill="#EFEFF0" />
                          <path
                            d="M20.3055 28.4444L24.7499 24M24.7499 24L29.1944 28.4444M24.7499 24V34M33.6388 29.2698C34.996 28.1489 35.861 26.4533 35.861 24.5556C35.861 21.1805 33.125 18.4444 29.7499 18.4444C29.5071 18.4444 29.28 18.3178 29.1567 18.1086C27.7078 15.6498 25.0326 14 21.9721 14C17.3698 14 13.6388 17.731 13.6388 22.3333C13.6388 24.629 14.5671 26.7079 16.0687 28.215"
                            stroke="#364077"
                            strokeWidth="2.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </label>
                    <label htmlFor="logo" className="cursor-pointer text-[#073C59] text-sm">
                      {fileName || t('logo')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </NewModal>
    </>
  )
}

export default CreateCompanyModal
