import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'

import { useGetCompanyGroupsQuery } from '../../../api/services/groupsApi'
import { useGetCompanyUsersQuery } from '../../../api/services/usersApi'
import useDarkMode from '../../../hooks/useDarkMode'
import { CreateUserInCompanyModal } from '../../modals/modals'
import { EditCompanyModal } from '../../modals/modals'
import DeleteUserModal from '../../modals/users/DeleteUserModal'
import EditUserModal from '../../modals/users/EditUserModal'
import { Button } from '../../root/Button'
import ColumnFilter from '../ColumnFilter'
import Table from '../Table'

const TableCompanyUsers = () => {
  const { t } = useTranslation()
  const columns = useMemo(
    () => [
      {
        Header: t('first_name'),
        accessor: 'firstName',
        Filter: ColumnFilter,
      },
      {
        Header: t('last_name'),
        accessor: 'lastName',
        Filter: ColumnFilter,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
      },
      {
        Header: t('username'),
        accessor: 'username',
        Filter: ColumnFilter,
      },
      {
        Header: t('group'),
        accessor: 'name',
        Cell: ({ row }) => {
          return (
            <div className="flex w-full justify-center">
              <p className="mb-0">{row.original.name || t('no_group')}</p>
            </div>
          )
        },
        Filter: ColumnFilter,
      },
      {
        Header: t('arch_access'),
        accessor: 'archive',
        Filter: ColumnFilter,
      },
      {
        Header: t('created2'),
        accessor: 'createdAt',
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt
          if (!createdAt) return ''
          return createdAt.substr(0, 10)
        },
        Filter: ColumnFilter,
      },
    ],
    [],
  )

  useDarkMode()

  const [openDelete, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openEditCopany, setOpenEditCopany] = useState(false)
  const [user, setUser] = useState({})
  const [newUser, setNewUser] = useState({})
  const [newGroup, setGroup] = useState()
  const [recipient, setRecipient] = useState()
  const [warning, setWarning] = useState()
  const [emailNotification, setEmailNotification] = useState()
  const [archive, setArchive] = useState()
  const [permissions, setPermissions] = useState([])

  const [openRegisterUser, setOpenRegisterUser] = useState(false)

  //EDIT COMPANY STATE
  const [newCompnay, setNewCompany] = useState({})
  const [tax, setTax] = useState()
  const [companyState, setCompanyState] = useState({})

  const company = useSelector((state) => state.company.company)

  const { data: companyUsers } = useGetCompanyUsersQuery(company?.id)
  const { data: companyGroups } = useGetCompanyGroupsQuery(company?.id)

  const handleClose = () => {
    setGroup([])
    setUser({})
    setRecipient()
    setWarning()
    setEmailNotification()
  }

  const permissionOptions = companyGroups
    ? companyGroups.map((companyGroup) => ({
        value: companyGroup?.id,
        label: companyGroup?.name,
      }))
    : []

  const data = useMemo(() => (Array.isArray(companyUsers) ? companyUsers : []), [companyUsers])

  const tableHooksArray = [
    {
      label: t('edit'),
      Icon: FaEdit,
      onClick: (row) => {
        setOpenEdit(true)
        setUser(row.original)
        setRecipient({ label: row.original.recipient === 1 ? 'True' : 'False', value: row.original.recipient })
        setWarning({ label: row.original.warning === 1 ? 'True' : 'False', value: row.original.warning })
        setEmailNotification({
          label: row.original.emailNotification === 1 ? 'True' : 'False',
          value: row.original.emailNotification,
        })
        setGroup({ label: row.original.name, value: row.original.groupId })
      },
      disabled: false,
    },
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        setOpenDelete(true)
        setUser(row.original)
      },
      disabled: false,
    },
  ]

  return (
    <>
      <Table data={data} columns={columns} actions filtering hooksArray={tableHooksArray} />
      <div className="w-full flex">
        <div className="w-full">
          <div className="flex gap-4 py-4">
            <Button
              onClick={() => {
                setOpenRegisterUser(!openRegisterUser)
              }}
            >
              {t('reg_user')}
            </Button>
            <Button
              onClick={() => {
                setOpenEditCopany(true)
                setTax(company.tax)
                setCompanyState(company)
              }}
            >
              {t('edit_company')}
            </Button>
          </div>
        </div>
      </div>

      <CreateUserInCompanyModal
        setPermissions={setPermissions}
        companyGroups={permissionOptions}
        onClose={() => {
          setRecipient()
          setWarning()
          setEmailNotification()
          setOpenRegisterUser(false)
        }}
        setRecipient={setRecipient}
        permissions={permissions}
        setArchive={setArchive}
        recipient={recipient}
        company={company}
        setUser={setUser}
        archive={archive}
        open={openRegisterUser}
        user={user}
        warning={warning}
        setWarning={setWarning}
        setEmailNotification={setEmailNotification}
        emailNotification={emailNotification}
      />
      <DeleteUserModal user={user} open={openDelete} companyId={company?.id} onClose={() => setOpenDelete(false)} />
      <EditUserModal
        onClose={() => {
          setOpenEdit(false)
          handleClose()
        }}
        setRecipient={setRecipient}
        companyId={company?.id}
        setArchive={setArchive}
        setNewUser={setNewUser}
        permissionOptions={permissionOptions}
        handleClose={handleClose}
        isPermissions
        recipient={recipient}
        stateUser={newUser}
        newGroup={newGroup}
        setGroup={setGroup}
        archive={archive}
        companyUsers={true}
        setUser={setUser}
        open={openEdit}
        user={user}
        warning={warning}
        setWarning={setWarning}
        isEditInCompany
        setEmailNotification={setEmailNotification}
        emailNotification={emailNotification}
      />
      <EditCompanyModal
        onClose={() => setOpenEditCopany(false)}
        setNewCompany={setNewCompany}
        newCompany={newCompnay}
        company={companyState}
        open={openEditCopany}
        setTax={setTax}
        tax={tax}
      />
    </>
  )
}
export default TableCompanyUsers
