import { t } from 'i18next'
import React from 'react'

import StampForFileForm from '../../components/forms/StampForFileForm'

const FileFormPage = () => {
  return <StampForFileForm />
}

export default FileFormPage
