import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useDeleteFileMutation } from '../../../api/services/filesApi'
import NewModal from '../../root/modals/NewModal'

const ConfirmDeleteModal = ({ open, onClose, fileId, isBooked }) => {
  const [deleteFile] = useDeleteFileMutation()

  const { t } = useTranslation()

  const handleDelete = async () => {
    try {
      if (isBooked) return toast.warn(t('doc_is_booked'))
      const response = await deleteFile(fileId).unwrap()
      toast.success(response.message)
      onClose(!onClose)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        title={t('you_sure_doc')}
        hideClose
        titleClose={t('close')}
        titleSubmit={t('delete')}
        btns
        onClickClose={onClose}
        onClickSubmit={handleDelete}
        noChildren
      />
    </>
  )
}

export default ConfirmDeleteModal
