import { api } from '../api'

export const functionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGroupFunctions: builder.query({
      query: ({ groupId }) => ({
        url: `/logs/get-logs/${groupId}`,
        method: 'GET',
      }),
      providesTags: ['Function'],
    }),
    updateGroup: builder.mutation({
      query: (body) => ({
        url: `/functions/update-functions`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Function', 'Groups'],
    }),
    getAllFunctions: builder.query({
      query: () => ({
        url: `/functions/get-functions`,
        metod: 'GET',
      }),
      providesTags: ['Function'],
    }),
  }),
})

export const { useGetGroupFunctionsQuery, useUpdateGroupMutation, useGetAllFunctionsQuery } = functionsApi
