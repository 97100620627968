export const RIFoptions = [
  {
    label: '1 - REVENUES FROM CORE BUSINESS',
    value: '1 - REVENUES FROM CORE BUSINESS',
  },
  { label: '2- INTERCOMPANY REVENUES', value: '2- INTERCOMPANY REVENUES' },
  { label: '3 - OTHER REVENUES', value: '3 - OTHER REVENUES' },
  {
    label:
      '4 - DISCOUNTS - SUBSCRIPTIONS TO CUSTOMERS AND RETURNS FOR NON-CONFORMITY',
    value:
      '4 - DISCOUNTS - SUBSCRIPTIONS TO CUSTOMERS AND RETURNS FOR NON-CONFORMITY',
  },
  { label: '5 - REVENUES', value: '5 - REVENUES' },
  { label: '50 - CONSUMPTION', value: '50 - CONSUMPTION' },
  {
    label: '17 - VARIATION ON STOCK OF RAW MATERIAL',
    value: '17 - VARIATION ON STOCK OF RAW MATERIAL',
  },
  {
    label: '18 - VARIATION ON STOCK OF PURCHASED COMPONENTS',
    value: '18 - VARIATION ON STOCK OF PURCHASED COMPONENTS',
  },
  {
    label: '19 - VARIATION ON STOCK OF FINISHED PRODUCT',
    value: '19 - VARIATION ON STOCK OF FINISHED PRODUCT',
  },
  { label: '20 - RAW MATERIAL', value: '20 - RAW MATERIAL' },
  { label: '21 - SEMIFINISHED', value: '21 - SEMIFINISHED' },
  { label: '22 - FINISHED PRODUCT', value: '22 - FINISHED PRODUCT' },
  { label: '23 - OTHER CONSUMABLES', value: '23 - OTHER CONSUMABLES' },
  {
    label: '25 - SALARY: DIRECT WORKERS',
    value: '25 - SALARY: DIRECT WORKERS',
  },
  {
    label: '27 - COST OF INTERIM WORKERS',
    value: '27 - COST OF INTERIM WORKERS',
  },
  { label: '28 - TREATMENTS', value: '28 - TREATMENTS' },
  {
    label: '29 - CONTRACT MANUFACTURING',
    value: '29 - CONTRACT MANUFACTURING',
  },
  {
    label: '40 - TRANSPORT ON PURCHASES',
    value: '40 - TRANSPORT ON PURCHASES',
  },
  { label: '31 - UTILITIES', value: '31 - UTILITIES' },
  {
    label: '32 - OTHERS INDUSTRIAL COST',
    value: '32 - OTHERS INDUSTRIAL COST',
  },
  {
    label: '34 - INDUSTRIAL MAINTENANCE',
    value: '34 - INDUSTRIAL MAINTENANCE',
  },
  {
    label: '42 - COST OF INDUSTRIAL VEHICLES',
    value: '42 - COST OF INDUSTRIAL VEHICLES',
  },
  { label: '24 - SALARY: LOGISTIC AREA', value: '24 - SALARY: LOGISTIC AREA' },
  { label: '26.1 - SALARY: PRODUCTION', value: '26.1 - SALARY: PRODUCTION' },
  {
    label: '26.2 - SALARY: TECHNICAL DEPARTMENT',
    value: '26.2 - SALARY: TECHNICAL DEPARTMENT',
  },
  {
    label: '26.2 - SALARY: TECHNICAL DEPARTMENT',
    value: '26.2 - SALARY: TECHNICAL DEPARTMENT',
  },
  { label: '26.3 - SALARY: MAINTENANCE', value: '26.3 - SALARY: MAINTENANCE' },
  { label: '26.4 - SALARY: PURCHASE', value: '26.4 - SALARY: PURCHASE' },
  {
    label: '26.5 - SALARY: QUALITY DEPARTMENT',
    value: '26.5 - SALARY: QUALITY DEPARTMENT',
  },
  {
    label: '36 - LEASING & RENTAL INDUSTRIAL',
    value: '36 - LEASING & RENTAL INDUSTRIAL',
  },
  { label: '39 - IT - INDUSTRIAL', value: '39 - IT - INDUSTRIAL' },
  { label: '43 - INDUSTRIAL INSURANCE', value: '43 - INDUSTRIAL INSURANCE' },
  { label: '45 - BUILT RENT', value: '45 - BUILT RENT' },
  { label: '46 - INDUSTRIAL CONSULTANT', value: '46 - INDUSTRIAL CONSULTANT' },
  {
    label: '47 - INDUSTRIAL DEPRECIATION',
    value: '47 - INDUSTRIAL DEPRECIATION',
  },
  {
    label: '85 - SALES & MARKETING COST',
    value: '85 - SALES & MARKETING COST',
  },
  {
    label: '61 - TRAVEL & TRANSFERS COST',
    value: '61 - TRAVEL & TRANSFERS COST',
  },
  {
    label: '62 - COST OF COMMERCIAL VEHICLES',
    value: '62 - COST OF COMMERCIAL VEHICLES',
  },
  {
    label: '62 - COST OF COMMERCIAL VEHICLES',
    value: '62 - COST OF COMMERCIAL VEHICLES',
  },
  {
    label: '64 - COMMERCIAL MAINTENANCE',
    value: '64 - COMMERCIAL MAINTENANCE',
  },
  { label: '69 - SALES TRANSPORT', value: '69 - SALES TRANSPORT' },
  { label: '70 - SALES CONSULTANT', value: '70 - SALES CONSULTANT' },
  { label: '71 - ADVERTISEMENT COST', value: '71 - ADVERTISEMENT COST' },
  { label: '72 - SALES COMMISSIONS', value: '72 - SALES COMMISSIONS' },
  { label: '73 - SALARY: SALES DEPT', value: '73 - SALARY: SALES DEPT' },
  {
    label: '76 - PROVISIONS FOR WRITE-DOWN CREDITS',
    value: '76 - PROVISIONS FOR WRITE-DOWN CREDITS',
  },
  {
    label: '77 - COMMERCIAL DEPRECIATION',
    value: '77 - COMMERCIAL DEPRECIATION',
  },
  { label: '79 - IT - COMMERCIAL', value: '79 - IT - COMMERCIAL' },
  { label: '80 - OTHER COMMERCIAL COST', value: '80 - OTHER COMMERCIAL COST' },
  { label: '100 - OVERHEADS COST', value: '100 - OVERHEADS COST' },
  {
    label: '87 - COST OF GENERAL VEHICLES',
    value: '87 - COST OF GENERAL VEHICLES',
  },
  { label: '88 - LEASING - GENERAL', value: '88 - LEASING - GENERAL' },
  { label: '89 - GENERAL DEPRECIATION', value: '89 - GENERAL DEPRECIATION' },
  {
    label: '90 - SUBSCRIPTION ASSOCIATIONS',
    value: '90 - SUBSCRIPTION ASSOCIATIONS',
  },
  { label: '91 - BOARD OF DIRECTION', value: '91 - BOARD OF DIRECTION' },
  { label: '92 - GENERAL MAINTENANCE', value: '92 - GENERAL MAINTENANCE' },
  { label: '93 - INSURANCE', value: '93 - INSURANCE' },
  { label: '94 - GENERAL COST', value: '94 - GENERAL COST' },
  { label: '95 - GENERAL CONSULTANT', value: '95 - GENERAL CONSULTANT' },
  { label: '96 - LEGAL CONSULTANT', value: '96 - LEGAL CONSULTANT' },
  {
    label: '97 - ADMINISTRATIVE CONSULTANT',
    value: '97 - ADMINISTRATIVE CONSULTANT',
  },
  {
    label: '98 - SALARY: ADMINISTRATIVE PERSONNEL',
    value: '98 - SALARY: ADMINISTRATIVE PERSONNEL',
  },
  {
    label: '99 - COST OF TEMPORARY STAFF - GENERAL',
    value: '99 - COST OF TEMPORARY STAFF - GENERAL',
  },
  { label: '100 - IT - GENERAL', value: '100 - IT - GENERAL' },
  { label: '101 - BANK COST', value: '101 - BANK COST' },
  { label: '102 - INTEREST PAYABLE', value: '102 - INTEREST PAYABLE' },
  { label: '103 - INTEREST INCOME', value: '103 - INTEREST INCOME' },
  { label: '104 - CAPITAL LOSS', value: '104 - CAPITAL LOSS' },
  { label: '105 - CAPITAL GAIN', value: '105 - CAPITAL GAIN' },
  {
    label: '106 - OTHER NOT CHARACTERISTIC',
    value: '106 - OTHER NOT CHARACTERISTIC',
  },
  {
    label: '110 - RIVALUTAZIONE AMMORTAMENTI - ITALY',
    value: '110 - RIVALUTAZIONE AMMORTAMENTI - ITALY',
  },
  {
    label: '170 - GOODWILL (DEPRECIATION)',
    value: '170 - GOODWILL (DEPRECIATION)',
  },
  {
    label: '-',
    value: '-',
  },
]
