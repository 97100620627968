import React from 'react'

const OpenSidebarBtn = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_188_6809)">
        <g clipPath="url(#clip0_188_6809)">
          <rect x="4" y="4" width="32" height="32" rx="16" fill="#006EAB" />
          <path
            d="M20.5839 12.0839L20.5838 12.0841L14.0858 18.5883C14.0855 18.5887 14.0851 18.589 14.0848 18.5894C13.2995 19.3674 13.3026 20.6347 14.0839 21.4161L14.0843 21.4164L20.5821 27.9017C20.5825 27.9021 20.5828 27.9024 20.5832 27.9028C21.3637 28.6904 22.6295 28.6756 23.4079 27.9054C24.1942 27.1275 24.1915 25.8594 23.4098 25.0777L18.3259 19.9937L23.4079 14.9117C23.4083 14.9113 23.4086 14.911 23.409 14.9106C24.1966 14.1302 24.1819 12.8643 23.4117 12.0858C22.6337 11.2995 21.3657 11.3022 20.5839 12.0839ZM30.9602 9.03984C33.867 11.9467 35.5 15.8891 35.5 20C35.5 24.1109 33.867 28.0533 30.9602 30.9602C28.0533 33.867 24.1109 35.5 20 35.5C15.8891 35.5 11.9467 33.867 9.03984 30.9602C6.13303 28.0533 4.5 24.1109 4.5 20C4.5 15.8891 6.13303 11.9467 9.03984 9.03984C11.9467 6.13303 15.8891 4.5 20 4.5C24.1109 4.5 28.0533 6.13303 30.9602 9.03984Z"
            fill="white"
            stroke="#D5D9EC"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_188_6809"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_188_6809" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_188_6809" result="shape" />
        </filter>
        <clipPath id="clip0_188_6809">
          <rect x="4" y="4" width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OpenSidebarBtn
