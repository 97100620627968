import { t } from 'i18next'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Button } from '../../../../root/Button'

const AddInputField = ({ onClick, nonSelectStampFields, setNonSelectStampFields, filteredOptions, index }) => {
  const addInputField = (e) => {
    e.preventDefault()
    if (filteredOptions.lenght !== 0) {
      let newFields = [...nonSelectStampFields]
      newFields.splice(index + 1, 0, {
        uId: uuidv4(),
        attributeKey: '',
        attributeValue: '',
        select: false,
      })

      setNonSelectStampFields(newFields)
    } else {
      return
    }

    onClick()
  }

  return (
    <Button onClick={addInputField} className="text-xl font-semibold">
      {t('add_input')}
    </Button>
  )
}

export default AddInputField
