import { ErrorMessage, useField, useFormikContext } from 'formik'
import { t } from 'i18next'
import React from 'react'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'

const DateInput = ({ noLabel, label, ...props }) => {
  const { setFieldValue } = useFormikContext()

  const [field] = useField(props)

  const handleDateChange = (date) => {
    setFieldValue(field?.name, date)
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value
    let formattedValue = inputValue ? inputValue.replace(/[^\d]/g, '') : ''

    if (formattedValue.length > 2) {
      const day = parseInt(formattedValue.slice(0, 2), 10)
      if (day >= 1 && day <= 31) {
        formattedValue = `${formattedValue.slice(0, 2)}.${formattedValue.slice(2)}` // Insert dot after two digits
      } else {
        formattedValue = `${formattedValue.slice(0, 2)}.`
        toast.error(t('wrong_day'))
      }
    }

    if (formattedValue.length > 5) {
      const month = parseInt(formattedValue.slice(3, 5), 10)
      if (month >= 1 && month <= 12) {
        formattedValue = `${formattedValue.slice(0, 5)}${formattedValue.slice(5)}` // Insert dot after five digits
      } else {
        // Dodaj tačku i onemogući dalji unos
        formattedValue = `${formattedValue.slice(0, 5)}`
        toast.error(t('wrong_month'))
      }
    }

    if (formattedValue.length > 5) {
      formattedValue = `${formattedValue.slice(0, 5)}.${formattedValue.slice(5)}` // Insert dot after five digits
    }

    if (formattedValue.length > 10) {
      formattedValue = formattedValue.slice(0, 10)
    }

    if (formattedValue !== inputValue || (inputValue && !isNaN(Number(inputValue)))) {
      event.target.value = formattedValue
      const dateParts = formattedValue.split('.').filter(Boolean)
      const day = parseInt(dateParts[0], 10)
      const month = parseInt(dateParts[1], 10) - 1
      const year = parseInt(dateParts[2], 10) || 0

      const date = new Date(year, month, day)

      if (!isNaN(date.getTime())) {
        setFieldValue(field.name, date)
      } else {
        setFieldValue(field.name, null)
      }
    }
  }

  const maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() + 100)

  return (
    <fieldset className="flex w-full">
      {!noLabel && <label htmlFor={field?.name}>{label}</label>}
      <div className="customDatePickerWidth">
        <DatePicker
          autoComplete="off"
          {...field}
          {...props}
          selected={(field?.value && new Date(field?.value)) || null}
          onChange={handleDateChange}
          locale="bs"
          dateFormat="dd.MM.yyyy"
          strictParsing
          // placeholderText={'dan.mjesec.godina'}
          portalId="root-portal"
          onChangeRaw={handleInputChange}
          className="border-2 border-neutral font-thin tracking-tight focus:border-neutral-200 capitalize placeholder:text-gray-300 w-full p-2 rounded-xl"
          maxDate={maxDate}
        />
      </div>
      <ErrorMessage component="span" name={field.name} className="text-xs text-error" />
    </fieldset>
  )
}

export default DateInput
