import { format } from 'date-fns'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useState } from 'react'
import { MdRestorePage } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useAsyncDebounce } from 'react-table'

import { useCompanyDeleteFilesQuery } from '../../../api/services/filesApi'
import { ConfirmRestoreFileModal } from '../../modals/modals'
import ColumnFilterDatePagination from '../components/ColumnFilterDatePagination'
import NewTable from '../NewTable'

const TableRestoreFiles = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})
  const [open, setOpen] = useState(false)
  const [fileId, setFileId] = useState(false)
  const [address, setAddress] = useState(false)

  const company = useSelector((state) => state.company.company)

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    queryString = queryString.replace(/&$/, '')

    return queryString
  }, [filterInputs])

  const {
    data: deletedFiles,
    isFetching,
    isLoading,
  } = useCompanyDeleteFilesQuery({
    companyId: company?.id,
    page,
    pageSize,
    query: query ? query : '',
  })

  const data = useMemo(() => (Array.isArray(deletedFiles?.files) ? deletedFiles?.files : []), [deletedFiles])

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('stamp_key'),
        accessor: 'stampKey',
      },
      {
        Header: t('name'),
        accessor: 'fileName',
        Cell: ({ row }) => {
          return row?.original?.fileName || 'Izdatnica'
        },
      },
      {
        Header: t('created1'),
        accessor: 'createdAt',
        Cell: ({ row }) => {
          const createdAt = row.original.createdAt
          if (!createdAt) return ''
          return createdAt.substr(0, 10)
        },
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
      {
        Header: t('edited'),
        accessor: 'updatedAt',
        Cell: ({ row }) => {
          const updatedAt = row.original.updatedAt
          if (!updatedAt) return ''
          return updatedAt.substr(0, 10)
        },
        Filter: ({ column }) => {
          return <ColumnFilterDatePagination column={column} handleFilterChange={handleFilterChange} />
        },
      },
    ],
    [],
  )

  const tableHooksArray = [
    {
      label: t('restore'),
      Icon: MdRestorePage,
      onClick: (row) => {
        setOpen(true)
        setFileId(row.original.id)
        setAddress(row.original.address)
      },
      disabled: false,
    },
  ]

  return (
    <>
      <NewTable
        columns={columns}
        data={data}
        setPage={setPage}
        pageManual={page}
        setPageAmount={setPageSize}
        controlledPageCount={deletedFiles?.totalPages || 0}
        count={deletedFiles?.totalCount}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
        hooksArray={tableHooksArray}
        actions
        filtering
        hiddenColumns={['address', 'id']}
        isFetching={isFetching}
        isLoading={isLoading}
      />

      <ConfirmRestoreFileModal open={open} onClose={() => setOpen(false)} fileId={fileId} />
    </>
  )
}
export default TableRestoreFiles
