import AddField from './buttons/AddField'
import RemoveField from './buttons/RemoveField'

const ActionButtons = ({
  spredValues,
  nonSelectStampFields,
  setNonSelectStampFields,
  setInputFields,
  nonSelect,
  inputField,
  index,
  shouldShow,
}) => {
  return (
    <div className="flex gap-2">
      {/* Some fields should not be removable
      That is why we don't want to show this btn for some fields */}
      <div>
        <AddField
          spredValues={spredValues}
          nonSelectStampFields={nonSelectStampFields}
          setNonSelectStampFields={setNonSelectStampFields}
          setInputFields={setInputFields}
          // stampOptions={stampOptions}
          index={index}
        />
      </div>
      <div>
        {shouldShow === true ? (
          <RemoveField
            index={index}
            inputField={inputField}
            nonSelect={nonSelect}
            spredValues={spredValues}
            setInputFields={setInputFields}
            nonSelectStampFields={nonSelectStampFields}
            setNonSelectStampFields={setNonSelectStampFields}
          />
        ) : null}
      </div>
    </div>
  )
}

export default ActionButtons
