import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Io from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { setCompany as setCompanyRedux } from '../../api/features/companySlice'
import { setUser } from '../../api/features/userSlice'
import { useUserCompaniesQuery } from '../../api/services/companiesApi'
import { useLazyUserFunctionQuery } from '../../api/services/usersApi'
import backgroundImage from '../../assets/images/Background.png'
import img from '../../assets/images/protocol-logo.png'

const CompanySelect = () => {
  const [company, setCompany] = useState()
  const [searchParams] = useSearchParams()

  const remember = searchParams.get('remember') ? true : false

  const user = useSelector((state) => state.user.user)

  const { data: userCompanies } = useUserCompaniesQuery(user?.id)

  const [getUserFunctions] = useLazyUserFunctionQuery()

  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const options = userCompanies?.map((company) => ({
    companyId: company.id,
    tax: company.tax,
    taxId: company.taxId,
    value: company.id,
    label: company.name || 'company label',
    address: company.address,
    logo: company.logo,
    ip: company.ip,
  }))

  const handleSelect = async (e) => {
    e.preventDefault()
    try {
      if (!company) return toast.error(t('select_company'))

      const userFunctions = await getUserFunctions({
        userId: user.id,
        companyId: company.companyId,
      }).unwrap()

      const selectedCompany = userCompanies.find((comp) => comp.id === company.value)

      const userFunctionNames = userFunctions[company.label][0].map((item) => item.name)

      const userObj = { ...user, functions: userFunctions[company.label][0] }

      if (remember) {
        localStorage.setItem('user', JSON.stringify(userObj))
        localStorage.setItem('company', JSON.stringify(selectedCompany))
      }
      dispatch(setUser(userObj))
      dispatch(setCompanyRedux(selectedCompany))

      navigate(`/${userFunctionNames[0]}`)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  const clientStyles = {
    control: (base, state) => ({
      ...base,
      height: '24px',
      background: '#ffffff',
      color: '#212529',
      width: '100%',
      borderRadius: 5,
      marginTop: 10,
      marginBottom: 10,
      boxShadow: '0px 3px 4px 0px #333',
    }),
    placeholder: (base) => {
      return {
        ...base,
        color: '#65aad5',
      }
    },
    dropdownIndicator: (base) => ({
      ...base,
      color: '#0d5c8d',
    }),
  }

  return (
    <>
      <div
        className="w-full h-screen flex items-center justify-center "
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="relative flex flex-col w-[310px] lg:w-[397px] h-[487px] border-[1px] border-[#D5D9EC] rounded-t-[20px] rounded-b-[20px]">
          <div className="flex flex-col w-[310px] lg:w-[397px] h-[361px] gap-[40px] pt-[60px] pr-[40px] pb-[40px] pl-[40px] bg-[#006EAB] rounded-t-[20px]">
            <div className="flex lg:w-[266px] h-[80px] p-[8px] gap-[12px] justify-center items-center">
              <img src={img} className="w-[64px] h-[64px]" alt="logo" />
              <p className="lg:w-[174px] h-[45px] font-bold text-4xl text-[#FFFFFF] text-center leading-[45.25px] ">
                PROTOCOL
              </p>
            </div>

            <form className="w-[250px] lg:w-[317px] h-[141px] flex flex-col gap-[12px]">
              <div className="mb-10 w-full flex flex-col justify-center items-center mt-6">
                <Select
                  options={options}
                  styles={clientStyles}
                  placeholder={t('company')}
                  className="w-full lg:w-4/5"
                  onChange={(e) => setCompany(e)}
                />
              </div>
            </form>
          </div>
          <div className="flex  w-[310px] lg:w-[397px] h-[126px] py-5 px-10 bg-[#E7EAF8] rounded-b-[20px] items-center justify-center gap-[12px]">
            <button
              className="flex justify-center items-center w-[317px] h-[48px] rounded-[4px] py-[12px] px-[20px]  gap-[20px] bg-white text-[#006EAB] font-semibold font-popins text-base leading-6 "
              onClick={() => {
                navigate('/')
              }}
            >
              <div className="flex justify-evenly w-full items-center">
                <Io.IoIosArrowBack className="w-6 h-6" />
                <p className="mb-0">{t('return')}</p>
              </div>
            </button>
            <button
              className="flex justify-center items-center w-[317px] h-[48px] rounded-[4px] py-[12px] px-[20px]  gap-[20px] bg-[#006EAB] font-semibold font-popins text-base leading-6 text-[#FCFCFC]"
              onClick={handleSelect}
            >
              {t('submit_2')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanySelect
