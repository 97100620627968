import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import { deleteOnePlaceOfExpence, setPlaceOfExpence } from '../../api/features/fileSlice'
import { clientStyles } from '../../data/createStampSelectStyles'
import useGetOrgUnits from '../../hooks/createstamp/useGetOrgUnits'
import TextInput from '../inputs/TextInput'
import { Button } from '../root/Button'

const MjestoTroska = ({ list, setPageState, isOnScanDoc }) => {
  const organisationalUnits = useGetOrgUnits()
  const dispatch = useDispatch()

  const organisationOptions =
    organisationalUnits?.map((org) => {
      return { id: org.organizational_units_id, label: org.name, value: org.name }
    }) || []
  const { t } = useTranslation()

  const place = useSelector((state) => state.file.placeOfExpence)

  const initialValues = {
    organisationalUnitId: '',
    organisationalUnitName: '',
    value: '',
  }

  const handleRemove = (index) => {
    dispatch(deleteOnePlaceOfExpence(index))
  }

  const handleAdd = (values) => {
    const data = { ...values, id: Math.random() }
    dispatch(setPlaceOfExpence(data))
  }
  return (
    <>
      <div className="w-full flex flex-col mt-4 h-[97%]">
        <div className="flex flex-col gap-4 w-full h-full p-2">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              handleAdd(values)
              resetForm()
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form className="grid grid-cols-5 gap-4">
                  <div className="w-full col-span-2">
                    <Select
                      options={organisationOptions}
                      value={organisationOptions.find((option) => option.id === values.organisationalUnitId) || ''}
                      onChange={(option) => {
                        setFieldValue('organisationalUnitId', option?.id)
                        setFieldValue('organisationalUnitName', option?.label)
                      }}
                      styles={clientStyles}
                      name="organisationalUnitId"
                    />
                  </div>
                  <div className="w-full col-span-2">
                    <TextInput
                      type="number"
                      name="value"
                      placeholder={t('value')}
                      noLabel
                      onChange={(e) => {
                        setFieldValue('value', e.target.value)
                      }}
                    />
                  </div>

                  <button
                    type="submit"
                    className="bg-[#006EAB] w-full flex items-center justify-center gap-2 rounded disabled:cursor-not-allowed"
                    disabled={values?.organisationalUnitName === '' || values?.value === ''}
                  >
                    <span>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="9.5" fill="white" stroke="#A6A9AD" />
                        <path
                          d="M12.7692 7.76923C12.7692 7.34375 12.4255 7 12 7C11.5745 7 11.2308 7.34375 11.2308 7.76923V11.2308H7.76923C7.34375 11.2308 7 11.5745 7 12C7 12.4255 7.34375 12.7692 7.76923 12.7692H11.2308V16.2308C11.2308 16.6562 11.5745 17 12 17C12.4255 17 12.7692 16.6562 12.7692 16.2308V12.7692H16.2308C16.6562 12.7692 17 12.4255 17 12C17 11.5745 16.6562 11.2308 16.2308 11.2308H12.7692V7.76923Z"
                          fill="#006EAB"
                        />
                      </svg>
                    </span>
                    <p className="font-semibold text-[#FCFCFC] text-base">{t('add')}</p>
                  </button>
                </Form>
              )
            }}
          </Formik>
          {list && (
            <div className="w-full mt-4 h-full">
              <div className="bg-[#EDECE7] flex w-full justify-between h-[34px] items-center rounded-t-lg">
                <div className="w-full flex justify-center items-center">
                  <p className="text-[#073C59] font-popins text-sm font-semibold">{t('org')}</p>
                </div>
                <div className="w-full flex justify-center items-center">
                  <p className="text-[#073C59] font-popins text-sm font-semibold">{t('total')}</p>
                </div>
                <div className="w-full flex justify-center items-center">
                  <p className="text-[#073C59] font-popins text-sm font-semibold">{t('actions')}</p>
                </div>
              </div>
              <ul className="bg-[#F7F7F7] flex flex-col w-full h-3/4 items-center overflow-y-auto rounded-b-lg">
                {place?.map((trosak, index) => {
                  return (
                    <li key={index} className="flex justify-between items-center w-full py-2 mt-2">
                      <div className="flex w-full justify-center items-center">
                        <p className="text-[#073C59] font-popins text-sm font-semibold">
                          {trosak?.organisationalUnitName}
                        </p>
                      </div>
                      <div className="flex w-full justify-center items-center">
                        <p className="text-[#073C59] flex justify-center items-center w-full font-popins text-sm font-semibold">
                          {trosak?.value}
                        </p>
                      </div>
                      <span className="flex w-full justify-center items-center hover:cursor-pointer">
                        <MdDelete size={20} onClick={() => handleRemove(trosak?.id)} />
                      </span>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
        {isOnScanDoc ? (
          <div className="flex w-full justify-between">
            <div className="w-80">
              <Button
                white
                className="bg-primary w-full text-white font-bold rounded-md cursor-pointer py-2"
                onClick={() => {
                  setPageState(0)
                }}
              >
                {t('back')}
              </Button>
            </div>
            <div className="w-80">
              <Button
                className="bg-primary w-full text-white font-bold rounded-md cursor-pointer py-2"
                onClick={() => {
                  setPageState(2)
                }}
              >
                {t('Next')}
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default MjestoTroska
