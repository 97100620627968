import { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'

import Modal from '../../root/modals/Modal'
import NewModal from '../../root/modals/NewModal'

const DocumentUpdateModal = ({
  open,
  onClose,
  setDate,
  date,
  attachedDocs,
  setAttachedDocs,
  setAttachedDocuments,
  doc,
  index,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [state, setState] = useState()

  const parsedDate = date && date.toLocaleDateString('en-UK').replace(/\//g, '-')

  const onSave = () => {
    const newArr = [...attachedDocs]
    newArr[index] = {
      ...newArr[index],
      text: state?.text ? state?.text : doc?.text,
      parsedDate: parsedDate || doc.parsedDate,
      label: state?.label ? state?.label : doc?.label,
    }
    setAttachedDocs(newArr)
    dispatch(setAttachedDocuments(newArr))
    setDate(null)
    setState()
    onClose()
  }

  function handleChange(e, isDate) {
    if (isDate) {
      setState((prevState) => ({
        ...prevState,
        parsedDate: e,
      }))
    } else {
      const { name, value } = e.target
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  return (
    <>
      <NewModal
        showModal={open}
        setShowModal={onClose}
        title={t('edit_doc')}
        btns
        page={'middle'}
        titleClose={t('close')}
        onClickClose={onClose}
        titleSubmit={t('save')}
        onClickSubmit={onSave}
      >
        <div className="flex flex-col justify-evenly">
          <input
            className="w-full outline-none font-semibold h-[60px] min-w-[260px] rounded-xl border-[#0D5C8D] border-2 my-2 pl-4 text-[#0D5C8D] "
            dateFormat="dd/MM/yyyy"
            defaultValue={doc?.text}
            name="text"
            onChange={handleChange}
            placeholder={t('document')}
          />
          <input
            className="w-full outline-none font-semibold h-[60px] min-w-[260px] rounded-xl border-[#0D5C8D] border-2 my-2 pl-4 text-[#0D5C8D] "
            dateFormat="dd/MM/yyyy"
            defaultValue={doc?.label}
            name="label"
            onChange={handleChange}
            placeholder={t('label')}
          />
          <DatePicker
            className="w-full outline-none font-semibold h-[60px] min-w-[260px] rounded-xl border-[#0D5C8D] border-2 my-2 pl-4 text-[#0D5C8D] z-10"
            dateFormat="dd/MM/yyyy"
            selected={date}
            showYearDropdown
            scrollableYearDropdown
            placeholderText={t('date_of_submission')}
            onChange={(date) => setDate(date)}
          />
        </div>
      </NewModal>
    </>
  )
}

export default DocumentUpdateModal
