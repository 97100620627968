import React from 'react'

import useGETAPI from '../../../hooks/createstamp/useGETAPI'
import SelectInputRegular from '../SelectInputRegular'

const APISelectRegular = (props) => {
  const options = useGETAPI(props.api)

  return <SelectInputRegular options={options} {...props} />
}

export default APISelectRegular
