import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useGetProtocolCommentsQuery } from '../../../api/services/protocolApi'
import NewModal from '../../root/modals/NewModal'
import FileViewer from './FileViewer'

const ViewModal = ({
  open,
  onClose,
  address,
  children,
  displayComment,
  comment,
  viewArray,
  displayBtns,
  btns,
  page,
  id,
}) => {
  
  const [currentAddress, setCurrentAddress] = useState('')
  const [currentID, setCurrentID] = useState(0)

  
  const { data: comments, refetch } = useGetProtocolCommentsQuery(
    {
      fileId: currentID,
    },
    { skip: !currentID },
  )

  const currentIndex = viewArray?.findIndex((item) => item.address === currentAddress)

  const handleNextAddress = () => {
    if (currentIndex < viewArray?.length - 1) {
      setCurrentAddress(viewArray[currentIndex + 1].address)
      setCurrentID(viewArray[currentIndex + 1].id)
    }

    refetch()
  }

  const handlePreviousAddress = () => {
    if (currentIndex > 0) {
      setCurrentAddress(viewArray[currentIndex - 1].address)
      setCurrentID(viewArray[currentIndex - 1].id)
    }

    refetch()
  }
  useEffect(() => {
    setCurrentAddress(address)
    setCurrentID(id)
  }, [address])

  return (
    <NewModal
      showModal={open}
      onClickClose={onClose}
      setShowModal={onClose}
      onRightClick={handleNextAddress}
      onLeftClick={handlePreviousAddress}
      disabledLeft={currentIndex === 0}
      disabledRight={currentIndex === viewArray?.length - 1}
      displayBtns={displayBtns}
      title={displayBtns ? `${currentIndex + 1} / ${viewArray?.length}` : ' '}
      btns={btns}
      page={page}
      titleClose={t('close')}
    >
      <FileViewer address={currentAddress} displayComment={displayComment} />

      {displayComment && comments?.length > 0 && (
        <div>
          {comments.map((item, index) => (
            <div key={index} className="mt-2 border-2 border-[#0D5C8D] rounded-xl p-2">
              <p className="font-bold text-[#0D5C8D] flex justify-start">Username: {item.username}</p>
              <p className="h-full w-full flex text-start">{item.comment}</p>
            </div>
          ))}
        </div>
      )}
    </NewModal>
  )
}

export default ViewModal
