import React from 'react'

const StampIcon = () => {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_182_7265)">
        <path
          d="M18.0625 12.1788C18.0625 11.2272 18.5656 10.3631 19.1508 9.60845C19.8398 8.71704 20.25 7.60142 20.25 6.39282C20.25 3.49438 17.8984 1.14282 15 1.14282C12.1016 1.14282 9.75 3.49438 9.75 6.39282C9.75 7.60142 10.1602 8.71704 10.8492 9.60845C11.4344 10.3631 11.9375 11.2272 11.9375 12.1788C11.9375 13.8139 10.6086 15.1428 8.97344 15.1428H7.125C3.73984 15.1428 1 17.8827 1 21.2678C1 22.4108 1.73281 23.3842 2.75 23.7452V26.5178C2.75 27.967 3.92578 29.1428 5.375 29.1428H24.625C26.0742 29.1428 27.25 27.967 27.25 26.5178V23.7452C28.2672 23.3842 29 22.4108 29 21.2678C29 17.8827 26.2602 15.1428 22.875 15.1428H21.0266C19.3914 15.1428 18.0625 13.8139 18.0625 12.1788ZM23.75 23.8928V25.6428H6.25V23.8928H23.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_7265">
          <rect width="29" height="29" fill="white" transform="translate(0 0.142822)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StampIcon
