import React from 'react'

const DoubleChevronIcon = () => {
  return (
    <svg width="71" height="40" viewBox="0 0 71 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30 15L25.2 19.8L30 24.6"
        stroke="#222222"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45 15L40.2 19.8L45 24.6"
        stroke="#222222"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default DoubleChevronIcon
