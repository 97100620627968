import { t } from 'i18next'
import React, { useMemo } from 'react'

import NewModal from '../../root/modals/NewModal'
import NewTable from '../../table/NewTable'

const NotificationModal = ({ open, setOpenNotificationModal, data }) => {
  const dataForTable = useMemo(() => (Array.isArray(data) ? data : []), [data])
  const columns = useMemo(
    () => [
      {
        Header: t('stamp_key'),
        accessor: 'Šifra pečata',
      },
      {
        Header: t('client'),
        accessor: 'Naziv partnera',
      },
      {
        Header: t('subject'),
        accessor: 'Naziv fakture',
      },
      {
        Header: t('protocol_date'),
        accessor: 'Datum protokola',
      },
    ],
    [],
  )

  return (
    <NewModal
      title={t('awaiting_docs')}
      showModal={open}
      setShowModal={() => {
        setOpenNotificationModal(false)
      }}
      page="Table"
    >
      <NewTable columns={columns} data={dataForTable} actions={false} pagination={false} />
    </NewModal>
  )
}

export default NotificationModal
