import { useMemo } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCategoryAlt } from 'react-icons/bi'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import { useGetCategoryQuery } from '../../../api/services/categoriesApi'
import { CreateCategoriesModal, DeleteCategoryModal, UpdateCompanyCategory } from '../../modals/modals'
import { Button } from '../../root/Button'
import ColumnFilter from '../ColumnFilter'
import Table from '../Table'

const TableCompanyCategories = () => {
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'categoryName',
        Filter: ColumnFilter,
      },
      {
        Header: t('desc'),
        accessor: 'categoryDescription',
        Filter: ColumnFilter,
      },
    ],
    [],
  )

  const [isOpen, setIsOpen] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [category, setCategory] = useState()

  const [approval, setApproval] = useState(false)

  const company = useSelector((state) => state.company.company)

  const { data: allCategories } = useGetCategoryQuery(company?.id)

  const data = useMemo(() => (Array.isArray(allCategories) ? allCategories : []), [allCategories])
  const handleClose = () => {
    setOpenCreate(!openCreate)
  }

  const tableHooksArray = [
    {
      label: t('edit'),
      Icon: FaEdit,
      onClick: (row) => {
        setCategory(row.original)
        if (row.original.requiredApproval === 1) {
          setApproval(true)
        } else {
          setApproval(false)
        }
        setOpenUpdate(true)
      },
      disabled: false,
    },
    {
      label: t('delete'),
      Icon: FaTrashAlt,
      onClick: (row) => {
        setCategory(row.original)
        setOpenDelete(true)
      },
      disabled: false,
    },
  ]

  return (
    <div className="wrapper phone:hidden mt-2 flex flex-col gap-4">
      <div className="w-full flex justify-start">
        <div className="w-full max-w-xs">
          <Button onClick={() => setOpenCreate(true)}>{t('create_cat')}</Button>
        </div>
      </div>

      <Table data={data} columns={columns} actions filtering hooksArray={tableHooksArray} hiddenColumns={['address']} />

      <DeleteCategoryModal onClose={() => setOpenDelete(false)} category={category} open={openDelete} />
      <UpdateCompanyCategory
        onClose={() => setOpenUpdate(false)}
        approval={approval}
        category={category}
        open={openUpdate}
      />
      {/* Edit file categories */}
      <CreateCategoriesModal open={openCreate} close={handleClose} />
    </div>
  )
}
export default TableCompanyCategories
