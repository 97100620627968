import { Form, Formik } from 'formik'
import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useEditConfiguratorMutation, useGetConfiguratorQuery } from '../../../../api/services/configuratorApi'

function UsersBeforeApproval() {
  const company = useSelector((state) => state.company.company)
  const { data: config } = useGetConfiguratorQuery(company?.id)

  const initialValues = {
    ...config,
  }

  const [updateConfig] = useEditConfiguratorMutation()

  const togglePrintFormat = async () => {
    try {
      const newPrintFormat = config.usersNeeded === 'one' ? 'all' : 'one'
      const { num_key, ...updatedConfig } = config

      const response = await updateConfig({
        companyId: company?.id,
        updatedConfig: { ...updatedConfig, usersNeeded: newPrintFormat },
      }).unwrap()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={(values, { setSubmitting }) => {}}>
      {({ values }) => (
        <Form className="flex justify-between items-center w-full">
          <p className=" h-[20px] font-popins font-medium leading-[20px] text-center text-lg text-[#073C59]">
            {t('users_be_app')}
          </p>
          <div className="flex w-[245px] h-[29px] gap-[8px]">
            <label
              className={`border border-[#D5D9EC] font-popins font-normal leading-5 text-sm cursor-pointer w-[118px] h-[29px] rounded-[8px] flex justify-center items-center gap-4 p-2 ${config?.usersNeeded === 'one' ? ' text-white bg-[#006EAB]' : 'bg-white text-[#140F0F]'}`}
            >
              <input
                autoComplete="off"
                type="radio"
                id="export"
                checked={config?.usersNeeded === 'one'}
                className={`radio radio-xs checked:bg-white checked:radio-primary`}
                onChange={togglePrintFormat}
              />
              <p>{t('one')}</p>
            </label>

            <label
              className={`border border-[#D5D9EC] font-popins font-normal leading-5 text-sm cursor-pointer w-[118px] h-[29px] rounded-[8px] flex justify-center items-center gap-4 p-2 ${config?.usersNeeded === 'all' ? ' text-white bg-[#006EAB]' : 'bg-white text-[#140F0F]'}`}
            >
              <input
                autoComplete="off"
                type="radio"
                id="export"
                checked={config?.usersNeeded === 'all'}
                className={`radio radio-xs checked:bg-white checked:radio-primary`}
                onChange={togglePrintFormat}
              />
              <p>{t('many')}</p>
            </label>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default UsersBeforeApproval
