import { t } from 'i18next'
import React from 'react'

import TableGroups from '../../../components/table/companies/TableGroups'

function CompanyGroupsPage() {
  return <TableGroups />
}

export default CompanyGroupsPage
