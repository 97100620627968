import { ErrorMessage, useField, useFormikContext } from 'formik'
import React from 'react'

const TextInput = ({ noLabel, label, onChange, ...props }) => {
  const [field] = useField(props)

  const { setFieldValue } = useFormikContext()

  return (
    <fieldset className="flex flex-col w-full">
      {!noLabel && (
        <div className="w-full flex justify-start text-secondary">
          <label>{label}</label>
        </div>
      )}
      <input
        autoComplete="off"
        className="border-2 border-neutral font-thin tracking-tight focus:border-neutral-200  placeholder:text-gray-300 w-full p-2 rounded-xl"
        {...field}
        {...props}
        onChange={onChange ? onChange : (e) => setFieldValue(field.name, e.target.value)}
      />
      <ErrorMessage component="span" name={field.name} className="text-xs text-error" />
    </fieldset>
  )
}

export default TextInput
