import React from 'react'
import { useSelector } from 'react-redux'

import { useAssociatedFilesByCompanyAndUserQuery } from '../../api/services/filesApi'
import SidebarItemWrapper from './SidebarItemWrapper'

const SidebarAwaitingDocsLink = ({ link, openSidebar }) => {
  const user = useSelector((state) => state.user.user)
  const company = useSelector((state) => state.company.company)

  const { data: allAwaiting } = useAssociatedFilesByCompanyAndUserQuery({
    companyId: company?.id,
    userId: user?.id,
    page: 1,
    pageSize: 1,
  })
  return (
    <SidebarItemWrapper
      link={link}
      className="w-full flex justify-center tooltip tooltip-right hover:bg-white/10"
      data-tip={link.title}
    >
      <li
        key={link.title}
        className={`flex rounded-md cursor-pointer relative text-white text-sm items-center ${link.title === 0 && 'bg-light-white'} ${!openSidebar ? 'w-fit' : 'w-4/5'}`}
      >
        {allAwaiting?.totalCount > 0 ? (
          <span
            className={`absolute ${openSidebar ? 'left-[10%] top-[-10%]' : 'right-[2%] top-[-5%]'} bg-red-600 px-[2px] py-[1px] text-[12px] m-0 flex text-sm justify-center hover:text-[#fff] items-center rounded-full`}
          >
            {allAwaiting?.totalCount ? (
              <span className="bg-red-600 px-[6px] py-[1px] text-[12px] m-0 flex text-sm justify-center hover:text-[#fff] items-center rounded-full">
                {allAwaiting?.totalCount}
              </span>
            ) : (
              <p></p>
            )}
          </span>
        ) : (
          <p></p>
        )}
        <div className="gap-4 flex items-center">
          <p className="mb-0">{link.icon}</p>
          <span className={`${!openSidebar && 'hidden'} origin-left duration-200`}>{link.title}</span>
        </div>
      </li>
    </SidebarItemWrapper>
  )
}

export default SidebarAwaitingDocsLink
