import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useGetCompanyGroupsQuery } from '../../../api/services/groupsApi'
import { useEditUserMutation } from '../../../api/services/usersApi'
import { clientStyles } from '../../../data/createStampSelectStyles'
import NewModal from '../../root/modals/NewModal'

const EditUserModal = ({
  open,
  user,
  onClose,
  newGroup,
  setGroup,
  recipient,
  setRecipient,
  setNewCompanies,
  setUser,
  isPermissions,
  handleClose,
  isEditInCompany,
  setWarning,
  warning,
  setEmailNotification,
  emailNotification,
}) => {
  const { t } = useTranslation()
  const company = useSelector((state) => state.company.company)

  const [archive, setArchive] = useState()
  const [newUser, setNewUser] = useState({})
  const { data: companyGroups } = useGetCompanyGroupsQuery(company?.id)

  const [updateUser] = useEditUserMutation()

  useEffect(() => {
    if (user) setNewUser({ ...user, password: '' })
  }, [user])

  const handleChange = (e) => {
    setNewUser((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  //permission options
  const permissionOptions = companyGroups
    ? companyGroups?.map((companyGroup) => ({
        value: companyGroup?.id,
        label: companyGroup?.name,
      }))
    : []

  //archive options
  const archiveOptions = [
    { value: 'All', label: 'All' },
    { value: 'Mine', label: 'Mine' },
  ]
  const recipientOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]
  const warningOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]
  const emailNotificationOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]

  const subbimtEddit = async (e) => {
    e.preventDefault()

    try {
      if (!newUser.firstName || !newUser.lastName || !newUser.username || !newUser.email || !newUser.archive)
        return toast.error(t('input_all_fields'))

      const finalUser = {
        ...user,
        ...newUser,
        archive: archive ? archive.label : user.archive,
        recipient: recipient ? recipient.value : user.recipient,
        warning: warning ? warning.value : user.warning,
        emailNotification: emailNotification ? emailNotification.value : user.emailNotification,
        address: company?.address,
        password: newUser.password ? newUser.password : '',
        ...(isEditInCompany
          ? {
              groups: [
                {
                  name: newGroup.label,
                  id: newGroup.value,
                  value: newGroup.value,
                  label: newGroup.label,
                  companyId: company?.id,
                },
              ],
            }
          : {}),
      }
      const response = await updateUser({ userId: user.id, editedUsers: finalUser }).unwrap()
      onClose(handleClose)
      toast.success(response.message)
      setArchive()
    } catch (error) {
      toast.error(error.data.message)
    }

    onClose(handleClose)
    setArchive()
  }

  return (
    <>
      <NewModal
        showModal={open}
        closeModal={onClose}
        title={t('edit_user')}
        hideClose
        page={'middle'}
        btns
        titleClose={t('close')}
        titleSubmit={t('save')}
        onClickClose={() => {
          onClose()
          setGroup([])
          setUser({})
          if (setNewCompanies) setNewCompanies([])
        }}
        onClickSubmit={subbimtEddit}
      >
        <form className="grid grid-cols-2 gap-y-5 gap-x-8 pb-2" onSubmit={subbimtEddit}>
          <input type="submit" className="hidden" />

          <div className="flex flex-col">
            <label className="font-popins font-normal text-sm">{t('first_name')}</label>
            <input
              type="text"
              defaultValue={user.firstName}
              placeholder={t('first_name')}
              onChange={handleChange}
              name="firstName"
              className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
            />
          </div>
          <div className="flex flex-col">
            <label className="font-popins font-normal text-sm">{t('last_name')}</label>
            <input
              type="text"
              defaultValue={user.lastName}
              placeholder={t('last_name')}
              onChange={handleChange}
              name="lastName"
              className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
            />
          </div>
          <div className="flex flex-col ">
            <label className="font-popins font-normal text-sm">{t('email')}</label>
            <input
              type="text"
              defaultValue={user.email}
              placeholder={t('email')}
              onChange={handleChange}
              name="email"
              className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
            />
          </div>
          <div className="flex flex-col ">
            <label className="font-popins font-normal text-sm">{t('username')}</label>
            <input
              type="text"
              defaultValue={user.username}
              placeholder={t('username')}
              onChange={handleChange}
              name="username"
              className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
            />
          </div>

          <div className="flex flex-col ">
            <label className="font-popins font-normal text-sm">{t('password')}</label>
            <input
              type="password"
              placeholder={t('password')}
              onChange={handleChange}
              name="password"
              className="w-full h-full py-[9px] px-2 border-[1px] rounded-lg bg-[#FFFFFF] border-[#E6E6E5]  placeholder:text-[#073C59] font-popins font-normal text-sm"
            />
          </div>

          <div className="flex flex-col ">
            <label className="font-popins font-normal text-sm">{t('arc')}</label>
            <div className="">
              <Select
                styles={clientStyles}
                defaultValue={
                  user.archive === 'All' ? { value: 'All', label: 'All' } : { value: 'Mine', label: 'Mine' }
                }
                options={archiveOptions}
                placeholder={t('arc')}
                onChange={(e) => setArchive(e)}
              />
            </div>
          </div>

          <div className="flex flex-col ">
            <label className="font-popins font-normal text-sm">{t('receiver')}</label>
            <div className="">
              <Select
                styles={clientStyles}
                value={recipient}
                options={recipientOptions}
                placeholder={t('receiver')}
                onChange={(e) => setRecipient(e)}
              />
            </div>
          </div>

          {/* ADD WARNING */}
          <div className="flex flex-col ">
            <label className="font-popins font-normal text-sm">Warning</label>
            <div className="">
              <Select
                styles={clientStyles}
                value={warning}
                options={warningOptions}
                placeholder="Warning"
                onChange={(e) => setWarning(e)}
              />
            </div>
          </div>

          {/* Edit notification */}
          <div className="flex flex-col ">
            <label className="font-popins font-normal text-sm">Email notification</label>
            <div className="">
              <Select
                styles={clientStyles}
                value={emailNotification}
                options={emailNotificationOptions}
                placeholder="Email notification"
                onChange={(e) => setEmailNotification(e)}
              />
            </div>
          </div>

          {isPermissions && (
            <div className="flex flex-col ">
              <label className="font-popins font-normal text-sm">{t('Permssions')}</label>
              <div className="">
                <Select
                  styles={clientStyles}
                  value={newGroup}
                  options={permissionOptions}
                  placeholder={t('Permssions')}
                  onChange={(e) => {
                    setGroup(e)
                  }}
                />
              </div>
            </div>
          )}
        </form>
      </NewModal>
    </>
  )
}

export default EditUserModal
