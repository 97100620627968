const TableRow = ({ item }) => {
  const tableClass =
    'text-xs font-normal break-words p-1 overflow-hidden text-center border border-black align-middle border-collapse'
  return (
    <table className='items-center w-full bg-transparent border-t-2 border-collapse border-gray-400 border-solid table-fixed test '>
      <col style={{ width: '10%' }}></col>
      <col style={{ width: '16%' }}></col>
      <col style={{ width: '5%' }}></col>
      <col style={{ width: '5%' }}></col>
      <col style={{ width: '16%' }}></col>
      <col style={{ width: '5%' }}></col>
      <col style={{ width: '22%' }}></col>
      <col style={{ width: '5%' }}></col>
      <col style={{ width: '16%' }}></col>
      <tbody className=''>
        <tr className='border border-black'>
          <td className={tableClass}></td>
          <td rowSpan={3} className={tableClass}>
            {item['Predmet']}
          </td>
          <td className={tableClass}></td>
          <td className={tableClass}>
            {item['Datum prijema']?.substring(0, 2)}
          </td>
          <td className={tableClass}>{item['Klijent']}</td>
          <td className={tableClass}></td>
          <td className={tableClass}></td>
          <td rowSpan={3} className={tableClass}></td>
          <td rowSpan={3} className={tableClass}></td>
        </tr>
        <tr>
          <td rowSpan={2} className={tableClass}>
            {item['Sifra']}
          </td>
          <td className={tableClass}></td>
          <td className={tableClass}>
            {item['Datum prijema']?.substring(3, 5)}
          </td>
          <td className={tableClass}>{item['Lokacija']}</td>
          <td className={tableClass}></td>
          <td className={tableClass}></td>
        </tr>
        <tr className=''>
          <td className={tableClass}></td>
          <td className={tableClass + ''}>
            {item['Datum prijema']?.substring(6, 10)}
          </td>
          <td className={tableClass}></td>
          <td className={tableClass}></td>
          <td className={tableClass}></td>
        </tr>
      </tbody>
    </table>
  )
}

export default TableRow
