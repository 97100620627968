import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import 'tippy.js/dist/tippy.css'

import { useGetUserFunctionOnSelectCompanyQuery } from '../api/services/usersApi'
import { ArchiveIcon, DocIcon, LogoutIcon } from '../assets/icons'
import Logo from '../assets/images/protocol.ico'
import { LogoutModal } from '../components/modals/modals'
import SidebarListItem from './components/SidebarListItem'
import SidebarLogoutLink from './components/SidebarLogoutLink'

const MobileSidebar = () => {
  const [openLogut, setOpenLogut] = useState(false)

  const { t } = useTranslation()

  const { openSidebar } = useSelector((state) => state.sidebar)

  const user = useSelector((state) => state.user.user)
  const company = useSelector((state) => state.company.company)

  const { data, isFetching, isSuccess } = useGetUserFunctionOnSelectCompanyQuery(
    { userId: user?.id, companyId: company.id },
    { skip: !user?.id || !company.id },
  )

  const functions = !isFetching && isSuccess ? data[company.name][0] : []

  const SidebarLinks = [
    {
      title: t('awaiting_docs'),
      icon: <DocIcon className="h-10 w-10" />,
      link: '/awaitingDocs',
      auth_key: 'awaitingdocs',
    },
    {
      title: t('arch'),
      icon: <ArchiveIcon className="h-[28px] w-[28px]" />,
      link: '/archive',
      auth_key: 'archive',
    },
    {
      title: t('logout'),
      icon: <LogoutIcon className="h-[28px] w-[28px]" />,
      link: '',
    },
  ]

  const mapedFunctions = functions.map((fun) => fun.name)
  const filteredMenues = SidebarLinks.filter((link) =>
    link.auth_key ? mapedFunctions.find((fun) => fun === link.auth_key) : link,
  )

  return (
    <>
      <div
        className={`fixed top-0 right-0 lg:hidden ${
          openSidebar ? 'w-[14em]' : 'w-0'
        } bg-primary min-h-screen transition-all duration-300 overflow-hidden z-20`}
      >
        <div className={`flex w-full justify-center items-center pb-6 pt-10`}>
          <img
            src={Logo}
            className={`cursor-pointer duration-500 h-14 w-14 ${openSidebar && 'rotate-[360deg]'}`}
            alt=""
          />
          <h1
            className={`text-white origin-left ml-3 font-sans text-lg duration-200 uppercase font-semibold tracking-tight ${!openSidebar && 'hidden'}`}
          >
            Protocol
          </h1>
        </div>
        {/* Links */}
        <ul className="flex flex-col justify-center items-center w-full gap-8 pt-8">
          {filteredMenues.map((link) => {
            if (link.title === t('logout'))
              return (
                <SidebarLogoutLink key={link.title} link={link} openSidebar={openSidebar} setOpenLogut={setOpenLogut} />
              )
            return <SidebarListItem key={link.title} link={link} openSidebar={openSidebar} />
          })}
        </ul>
      </div>
      <LogoutModal open={openLogut} onClose={() => setOpenLogut(false)} />
    </>
  )
}
export default MobileSidebar
